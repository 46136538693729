import React, { useState } from "react";

import Footer from "../layout/Footer";

import classnames from "classnames";




const OurJourney = () => {
  const [status, setStatus] = useState("beginning");

  return (
    <>
      <div>
        <div className="relative fon">
          <div className="max-w-[1226px] w-full mx-auto relative">
            <div className="absolute top-0 right-[0] flex justify-end z-[2] overflow-hidden">
              <a target="_blank" href="https://twitter.com/pikamooncoin">
                <img
                  alt="x"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/X.svg"
                />
              </a>
              <a target="_blank" href="https://t.me/pikamoonofficial">
                <img
                  alt="Telegram"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/Telegram.svg"
                />
              </a>
              <a target="_blank" href="https://discord.com/invite/QFHbbKQed9">
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/Discord.svg"
                />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/pikamooncoin_/"
              >
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/Instagram.svg"
                />
              </a>
              <a target="_blank" href="https://www.youtube.com/@pikamooncoin">
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/youtubeicon.svg"
                />
              </a>
              <a
                target="_blank"
                href="https://www.reddit.com/r/officialpikamoon/"
              >
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/reddit.svg"
                />
              </a>
            </div>
          </div>
          <div className="marquee text-[14px] text-white bg-black relative h-[42px] md:h-[50px] flex items-center overflow-hidden">
            <section>
              <div className="flex items-center flex-nowrap min-w-fit font-[700]">
                <a className=" min-w-fit fontEnigma" href="/buy">
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="../images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
              </div>
            </section>
          </div>
        </div>
        <div className="z-[9] co-navbar  px-[20px] h-[80px] border-b border-b-[2.5px] border-black text-black bg-gradient-to-r from-[#ffbc00] to-[#ff9100] hidden md:block sticky top-[-2px] bg-[#ffffff a-z-30">
          <div className="max-w-[1223px] w-full h-full mx-auto block md:flex items-center gap-x-2 flex-wrap md:flex-nowrap lg:gap-x-10">
            <div className="flex md:block items-center justify-between">
              <a href="/">
                <img
                  alt="close"
                  loading="lazy"
                  width="80"
                  height="80"
                  decoding="async"
                  data-nimg="1"
                  className="relative top-[15px] transition-transform ease-in-out z-[1] hover:scale-[1.2] transform origin-center will-change-transform"
                  style={{ color: "transparent" }}
                  src="/images/logo.svg"
                />
              </a>

              <a
                className=" custom-bg-hover hover:text-white relative flex md:hidden justify-center items-center w-[148px] h-[35px]"
                href="/token"
              >
                 <img
                  alt="buyPikaArrow"
                  fetchpriority="high"
                  width="148"
                  height="35"
                  decoding="async"
                  data-nimg="1"
                  class=""
                  style={{ color: "transparent" }}
                  src="/images/buyPikaArrow.svg"
                />
              </a>
            </div>

            <ul className="flex gap-[10px] lg:gap-[25px] items-center justify-center  md:justify-start bg-gradient-to-r from-[#ffbc00] to-[#ff9100] p-[15px] md:p-0 md:bg-none  ">
              <li>
                <a
                  className=" menu-link-effect text-[18px] lg:text-[20px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                  href="/play-beta"
                >
                  Play BETA{" "}
                  <span className="absolute right-[-14px] top-0 opacity-0">
                    <img
                      alt="Menu"
                      loading="lazy"
                      width="26"
                      height="40"
                      decoding="async"
                      data-nimg="1"
                      src="../images/menu-shape.png"
                      style={{ color: "transparent" }}
                    />
                  </span>{" "}
                </a>
              </li>

              <li>
                <a
                  className="false bg-black menu-link-effect text-[18px] lg:text-[20px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                  href="/our-journey"
                >
                  Our Journey{" "}
                  <span className="absolute right-[-14px] top-0 opacity-0">
                    <img
                      alt="Menu"
                      loading="lazy"
                      width="26"
                      height="40"
                      decoding="async"
                      data-nimg="1"
                      src="../images/menu-shape.png"
                      style={{ color: "transparent" }}
                    />
                  </span>{" "}
                </a>
              </li>
            </ul>

            <div className="ml-auto flex items-center gap-x-1 lg:gap-x-2 2xl:gap-x-5" />
            <div>
              <a
                className=" custom-bg-hover hover:text-white relative hidden md:flex justify-center items-center w-[148px] h-[35px]"
                href="/token"
              >
                 <img
                  alt="buyPikaArrow"
                  fetchpriority="high"
                  width="148"
                  height="35"
                  decoding="async"
                  data-nimg="1"
                  class=""
                  style={{ color: "transparent" }}
                  src="/images/buyPikaArrow.svg"
                />
              </a>
            </div>

            <p className="hidden md:block bg-transparent outline-0 font-semibold text-[18px] capitalize tracking-[-0.36px]">
              ENG
            </p>
          </div>
        </div>

        <input type="checkbox" class="hidden menu-active" id="Mobile-menu"></input>
        <div class="mobile-header co-navbar px-[20px] h-auto text-black bg-gradient-to-r from-[#ffbc00] to-[#ff9100] block md:hidden relative z-[99] sticky top-[-2px]" >
          <div class="flex items-center gap-x-2 flex-wrap md:flex-nowrap lg:gap-x-10">
            <a href="/">
              <img
                alt="close"
                fetchpriority="high"
                width="80"
                height="80"
                decoding="async"
                data-nimg="1"
                class="logo-postion hover:scale-[1.2] transtionHoverLogo z-[1]"
                style={{ color: "transparent" }}
                src="/images/logo.svg"
              />
            </a>
            <div class="ml-auto flex items-center gap-x-[22px] lg:gap-x-2 2xl:gap-x-5">
              <a href="/token">
                <img
                  alt="buyPikaArrow"
                  fetchpriority="high"
                  width="148"
                  height="35"
                  decoding="async"
                  data-nimg="1"
                  class=""
                  style={{ color: "transparent" }}
                  src="/images/buyPikaArrow.svg"
                />
              </a>
              <label for="Mobile-menu">
                <img
                  alt="Mobile Menu"
                  loading="lazy"
                  width="29"
                  height="29"
                  decoding="async"
                  data-nimg="1"
                  style={{ color: "transparent" }}
                  src="/images/Mobile-menu.5ef721ad.svg"
                  class="menu-mobile1"
                />
                <img
                  alt="Mobile Menu"
                  loading="lazy"
                  width="29"
                  height="29"
                  decoding="async"
                  data-nimg="1"
                  class="menu-mobile2"
                  style={{ color: "transparent" }}
                  src="/images/menu-close.2bb71f01.svg"
                />
              </label>
            </div>
          </div>
          <ul class="mobile-menu-list ease-out flex flex-col gap-[5px] w-full max-h-[0] overflow-hidden opacity-0" >
         
            <li>
              <a
                class=" h-[40px] md:h-auto inline-flex menu-link-effect text-[18px] lg:text-[18px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                href="/play-beta"
              >
                Play Beta
                <span class="absolute right-[-14px] top-0 opacity-0">
                  <img
                    alt="Menu"
                    loading="lazy"
                    width="26"
                    height="40"
                    decoding="async"
                    data-nimg="1"
                    style={{ color: "transparent" }}
                    
                    src="/images/menu-shape.2f1d76fd.png&amp;w=64&amp;q=75"
                  />
                </span>
              </a>
            </li>
         
            <li>
              <a
                class="bg-black h-[40px] md:h-auto menu-link-effect inline-flex text-[18px] lg:text-[18px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                href="/our-journey"
              >
                Our Journey{" "}
                <span class="absolute right-[-14px] top-0 opacity-0">
                  <img
                    alt="Menu"
                    loading="lazy"
                    width="26"
                    height="40"
                    decoding="async"
                    data-nimg="1"
                    style={{ color: "transparent" }}
                   
                    src="images/menu-shape.2f1d76fd.png"
                  />
                </span>
              </a>
            </li>
          </ul>
        </div>





        <section className="pt-[60px]  pb-[20px] bg-[url('/images/bg-gradient.svg')] bg-cover bg-contain xl:bg-cover bg-repeat-x bg-center-top relative min-h-[1000px]">
          <div className="absolute top-[60px] bottom-0 left-0 right-0 !pointer-events-none">
            <div className="flex justify-between items-center lg:sticky left-0 right-0 top-[0]">
              <img
                alt="Cloud"
                loading="lazy"
                width="274"
                height="152"
                decoding="async"
                data-nimg="1"
                className="cloud-1 max-w-[40%]"
                src="../images/cloud-1.429b1c46.svg"
                style={{ color: "transparent;" }}
              />
              <img
                alt="Cloud"
                loading="lazy"
                width="319"
                height="160"
                decoding="async"
                data-nimg="1"
                className="cloud-2 max-w-[40%]"
                src="../images/cloud-2.73cacac8.svg"
                style={{ color: "transparent;" }}
              />
            </div>
            <div className="flex justify-between items-center lg:sticky left-0 right-0 top-[450px] mt-[450px]">
              <img
                alt="Cloud"
                loading="lazy"
                width="327"
                height="156"
                decoding="async"
                data-nimg="1"
                className="cloud-3 max-w-[40%]"
                src="../images/cloud-3.05115ebe.svg"
                style={{ color: "transparent;" }}
              />
              <img
                alt="Cloud"
                loading="lazy"
                width="299"
                height="253"
                decoding="async"
                data-nimg="1"
                className="cloud-4 max-w-[40%]"
                src="../images/cloud-4.5f81391a.svg"
                style={{ color: "transparent;" }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-[20px] mb-[30px] relative z-[2]">
            <h1 className="text-[#F24822] text-[32px] leading-[40px] text-center fontClash font-[700]">
              Journey (The Story)
            </h1>
            <p className="max-w-[370px] md:max-w-[580px] mx-auto text-[20px] leading-[28px] text-center text-black">
              Pikamoon is a Revolutionary Leap in Play-to-Earn Gaming and
              Community-Driven Metaverse
            </p>
          </div>
          <ul className="flex items-center  max-w-[454px] w-[85%]  h-[60px] bg-white rounded-[10px] justify-center mx-auto mb-[60px] fontClash relative z-[2]">
            <li className="flex-1">
              {" "}
              <button
                className={classnames(
                  {
                    "text-[#F24822] font-[700] border border-black":
                      status == "beginning",
                  },
                  "bg-white  font-[700] border  text-[24px] md:text-[28px] w-full p-[10px_20px] rounded-[10px] whitespace-nowrap"
                )}

                onClick={() => {
                    setStatus("beginning");
                  }}
              >
                The Beginning
              </button>{" "}
            </li>
            <li className="flex-1">
              {" "}
              <button
                className={classnames(
                  {
                    "text-[#F24822] font-[700] border border-black":
                      status == "future",
                  },
                  "bg-white  font-[700] border  text-[24px] md:text-[28px] w-full p-[10px_20px] rounded-[10px] whitespace-nowrap"
                )}
                onClick={() => {
                  setStatus("future");
                }}
              >
                The Future
              </button>{" "}
            </li>
          </ul>

          {status == "beginning" && (
            <div className="max-w-[1040px] w-full mx-auto relative flex flex-col gap-[70px] relative z-[2] px-[30px] max-h-[600px] overflow-auto hideScrollbar">
              <div className="w-full flex flex-col lg:flex-row gap-[30px] lg:gap-[100px] before:content-[''] before:w-[1px] before:absolute before:left-0 lg:before:inset-x-0 before:bottom-[-65px] before:top-[90px] before:bg-black before:mx-auto relative pl-[30px] lg:pl-0">
                <div className="w-full lg:w-1/2">
                  <div className="flex flex-col items-start lg:items-end lg:pr-[30px]">
                    <p className="w-full lg:text-right text-black text-[18px] leading-[25px] pl-[10px] lg:pl-0 lg:mr-[-30px]">
                      It starts with. . .
                    </p>
                    <div className="flex items-center flex-row-reverse lg:flex-row lg:justify-end gap-[20px] lg:gap-[30px] lg:mr-[-98px] relative ml-[-47px] lg:ml-0">
                      <h3 className="text-[#F24822] text-[26px] leading-[40px] fontClash font-[600] lg:text-right">
                        The Genesis of Pikamoon:{" "}
                      </h3>
                      <img
                        alt=""
                        loading="lazy"
                        width="36"
                        height="36"
                        decoding="async"
                        data-nimg="1"
                        src="../images/time-line1.9c781cf8.svg"
                        style={{ color: "transparent;" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-[20px]">
                  <img
                    alt=""
                    loading="lazy"
                    width="459"
                    height="221"
                    decoding="async"
                    data-nimg="1"
                    className="w-full rounded-[25px]"
                    src="../images/timeline-banner1.png"
                    style={{ color: "transparent;" }}
                  />
                  <div className="flex flex-col gap-[25px] text-[20px] leading-[28px]">
                    <p>
                      Born out of a shared passion for gaming and a bold vision
                      to revolutionize the web3 space, Pikamoon was conceived in
                      November 2022 by three friends and avid gamers. Inspired
                      by iconic games like Pokémon and driven by a desire to
                      challenge industry giants, they set out to create a
                      AAA-grade play-to-earn game unlike any other.
                    </p>
                    <p>
                      Pikamoon is not just a game; it's a rebellion against
                      traditional gaming, offering a community-focused,
                      decentralized alternative that empowers players.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex gap-[30px] lg:gap-[100px] flex-col lg:flex-row-reverse before:content-[''] before:w-[1px] before:absolute before:left-0 lg:before:inset-x-0 before:bottom-[-65px] before:top-[90px] before:bg-black before:mx-auto relative pl-[30px] lg:pl-0">
                <div className="w-full lg:w-1/2">
                  <div className="flex flex-col items-start lg:pr-[30px]">
                    <div className="flex items-center justify-end gap-[20px] lg:gap-[30px] ml-[-46px] lg:ml-[-68px] flex-row-reverse relative">
                      <h3 className="text-[#F24822] text-[26px] leading-[40px] fontClash font-[600]">
                        Setting the Standard with AAA Quality:
                      </h3>
                      <img
                        alt=""
                        loading="lazy"
                        width="36"
                        height="36"
                        decoding="async"
                        data-nimg="1"
                        src="../images/timeline2.fc9b3479.svg"
                        style={{ color: "transparent;" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-[20px]">
                  <img
                    alt=""
                    loading="lazy"
                    width="390"
                    height="221"
                    decoding="async"
                    data-nimg="1"
                    className="w-full rounded-[25px]"
                    src="../images/aaa.5150244b.svg"
                    style={{ color: "transparent;" }}
                  />
                  <div className="flex flex-col gap-[25px] text-[20px] leading-[28px] lg:text-right">
                    <p>
                      Pikamoon stands apart in the web3 realm with its
                      aspiration for AAA quality, setting a new benchmark for
                      play-to-earn games. Our metaverse, Dreva, is a vast,
                      immersive world where ecosystems from around the globe
                      converge, allowing players to explore, battle, and earn
                      rewards in groundbreaking ways.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col lg:flex-row gap-[30px] lg:gap-[100px] before:content-[''] before:w-[1px] before:absolute before:left-0 lg:before:inset-x-0 before:bottom-[-65px] before:top-[90px] before:bg-black before:mx-auto relative pl-[30px] lg:pl-0">
                <div className="w-full lg:w-1/2">
                  <div className="flex flex-col items-start lg:items-end lg:pr-[30px]">
                    <p className="text-black text-[18px] leading-[25px] lg:mr-[-30px]">
                      November 09, 2023
                    </p>
                    <div className="flex flex-row-reverse lg:flex-row items-center justify-start lg:justify-end gap-[20px] lg:gap-[30px] ml-[-45px] lg:ml-0 lg:mr-[-98px] relative">
                      <h3 className="text-[#F24822] text-[26px] leading-[40px] fontClash font-[600] lg:text-right">
                        A Community-Driven Journey:
                      </h3>
                      <img
                        alt=""
                        loading="lazy"
                        width="36"
                        height="36"
                        decoding="async"
                        data-nimg="1"
                        src="../images/p-group.e23e8f0b.svg"
                        style={{ color: "transparent;" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-[20px]">
                  <a
                    href="https://twitter.com/PikaMoonCoin/status/1722341443346276822?s=20"
                    target="_blank"
                  >
                    <img
                      alt=""
                      loading="lazy"
                      width="413"
                      height="371"
                      decoding="async"
                      data-nimg="1"
                      className="w-full rounded-[25px]"
                      src="../images/timeline-tweet-1.70d1287e.svg"
                      style={{ color: "transparent;" }}
                    />
                  </a>
                  <div className="flex flex-col gap-[25px] text-[20px] leading-[28px]">
                    <p>
                      Unique in its funding and governance, Pikamoon is entirely
                      community-driven, with no external VC or institutional
                      backing. This approach ensures that our early backers, who
                      believe in our vision, are at the core of our
                      decision-making process.
                    </p>
                    <p>
                      Our success is a testament to the power of community
                      support and shared belief in a transformative gaming
                      experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col gap-[30px] lg:gap-[100px] lg:flex-row-reverse before:content-[''] before:w-[1px] before:absolute before:left-0 lg:before:inset-x-0 before:bottom-[-65px] before:top-[90px] before:bg-black before:mx-auto relative pl-[30px] lg:pl-0">
                <div className="w-full lg:w-1/2">
                  <div className="flex flex-col items-start lg:pr-[30px]">
                    <div className="flex items-center justify-end gap-[20px] lg:gap-[30px] ml-[-45px] lg:ml-[-68px] flex-row-reverse relative">
                      <h3 className="text-[#F24822] text-[26px] leading-[40px] fontClash font-[600]">
                        Redefining the Open Metaverse:
                      </h3>
                      <img
                        alt=""
                        loading="lazy"
                        width="36"
                        height="36"
                        decoding="async"
                        data-nimg="1"
                        src="../images/ball.b891aaa4.svg"
                        style={{ color: "transparent;" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-[20px]">
                  <img
                    alt=""
                    loading="lazy"
                    width="420"
                    height="220"
                    decoding="async"
                    data-nimg="1"
                    className="w-full rounded-[25px]"
                    src="../images/timeline-decLand.07f72c2f.svg"
                    style={{ color: "transparent;" }}
                  />
                  <div className="flex flex-col gap-[25px] text-[20px] leading-[28px] lg:text-right">
                    <p>
                      While existing metaverses like The Sandbox and
                      Decentraland have laid the groundwork, Pikamoon aims
                      higher with a AAA-grade standard
                    </p>
                    <p>
                      Our open metaverse is a space where diverse communities
                      can gather, compete, and earn, setting new standards for
                      engagement and immersion.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col lg:flex-row gap-[30px] lg:gap-[100px] before:content-[''] before:w-[1px] before:absolute before:left-0 lg:before:inset-x-0 before:bottom-[-65px] before:top-[90px] before:bg-black before:mx-auto relative pl-[30px] lg:pl-0">
                <div className="w-full lg:w-1/2">
                  <div className="flex flex-col items-start lg:items-end lg:pr-[30px]">
                    <div className="flex items-center min-h-[90px] flex-row-reverse lg:flex-row justify-end gap-[20px] lg:gap-[30px] ml-[-44px] lg:ml-0 lg:mr-[-98px] relative">
                      <h3 className="text-[#F24822] text-[26px] leading-[40px] fontClash font-[600] lg:text-right">
                        Milestones &amp; Partnerships:
                      </h3>
                      <img
                        alt=""
                        loading="lazy"
                        width="36"
                        height="36"
                        decoding="async"
                        data-nimg="1"
                        src="../images/milestone.6d526c69.svg"
                        style={{ color: "transparent;" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-[20px]">
                  <a
                    href="https://twitter.com/KevuruGames/status/1659531770071592960?s=20"
                    target="_blank"
                  >
                    <img
                      alt=""
                      loading="lazy"
                      width="413"
                      height="371"
                      decoding="async"
                      data-nimg="1"
                      className="w-full rounded-[25px]"
                      src="../images/timeline-tweet.19928389.svg"
                      style={{ color: "transparent;" }}
                    />
                  </a>
                  <div className="flex flex-col gap-[25px] text-[20px] leading-[28px]">
                    <p>
                      Our journey so far has been marked by significant
                      achievements, including the release of our Beta Battle
                      System for iOS and Android, and the success of our
                      presale.
                    </p>
                    <p>
                      Our partnership with Kevuru Games, renowned for their work
                      on Fortnite, EA, and Star Wars titles, underscores our
                      commitment to delivering top-tier gaming experiences.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex-col flex gap-[30px] lg:gap-[100px] lg:flex-row-reverse relative before:content-[''] before:w-[1px] before:absolute before:left-0 lg:before:inset-x-0 before:bottom-[-65px] before:top-[90px] before:bg-black before:mx-auto relative pl-[30px] lg:pl-0">
                <div className="w-full lg:w-1/2">
                  <div className="flex flex-col items-start lg:pr-[30px]">
                    <div className="flex items-center md:h-[80px] justify-end gap-[20px] lg:gap-[30px] ml-[-44px] lg:ml-[-68px] flex-row-reverse relative">
                      <h3 className="text-[#F24822] text-[26px] leading-[40px] fontClash font-[600]">
                        Overcoming Challenges:
                      </h3>
                      <img
                        alt=""
                        loading="lazy"
                        width="36"
                        height="36"
                        decoding="async"
                        data-nimg="1"
                        src="../images/challenge.cda93527.svg"
                        style={{ color: "transparent;" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-[20px]">
                  <img
                    alt=""
                    loading="lazy"
                    width="463"
                    height="195"
                    decoding="async"
                    data-nimg="1"
                    className="w-full"
                    src="../images/timeline-slider.234f061f.svg"
                    style={{ color: "transparent;" }}
                  />
                  <div className="flex flex-col gap-[25px] text-[20px] leading-[28px] lg:text-right">
                    <p>
                      Starting without VC backing presented initial financial
                      constraints, but our founders' investment and the
                      community's financial overwhelming support enabled us to
                      swiftly develop our Beta version.
                    </p>
                    <p>
                      This journey, though challenging, has been a testament to
                      our resilience and dedication to our vision.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col lg:flex-row gap-[30px] lg:gap-[100px] relative pl-[30px] lg:pl-0">
                <div className="w-full lg:w-1/2">
                  <div className="flex flex-col items-start lg:items-end lg:pr-[30px]">
                    <div className="flex items-center md:h-[80px] flex-row-reverse lg:flex-row justify-end gap-[20px] lg:gap-[30px] ml-[-44px] lg:ml-0 lg:mr-[-98px] relative">
                      <h3 className="text-[#F24822] text-[26px] leading-[40px] fontClash font-[600] lg:text-right">
                        An Invitation to Join Our Odyssey:
                      </h3>
                      <img
                        alt=""
                        loading="lazy"
                        width="36"
                        height="36"
                        decoding="async"
                        data-nimg="1"
                        src="../images/console.bafe055f.svg"
                        style={{ color: "transparent;" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-[20px]">
                  <iframe
                    width="100%"
                    height="474"
                    src="https://www.youtube.com/embed/cu2-nTMYanQ?autoplay=1&amp;mute=1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen=""
                    className="rounded-md md:rounded-3xl w-full h-[300px] md:h-[474px]"
                  ></iframe>
                  <div className="flex flex-col gap-[25px] text-[20px] leading-[28px]">
                    <p>
                      “Pikamoon is more than a game; it's a movement. With over
                      15,000 investors and growing, our community is the
                      heartbeat of this project. We extend an invitation to
                      gamers, dreamers, and revolutionaries to join us in
                      challenging the status quo of gaming. Together, let's make
                      Pikamoon the epitome of play-to-earn gaming and a
                      formidable force in the web3 world.”
                    </p>
                    <ul className="list-disc pl-8">
                      <li>Nixx - Co-Founder</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          {status == "future" && (
            <div class="max-w-[1040px] w-full mx-auto relative flex flex-col gap-[70px] z-[2] px-[30px] max-h-[600px] overflow-auto hideScrollbar">
              <div class="w-full flex flex-col lg:flex-row gap-[30px] lg:gap-[100px] before:content-[''] before:w-[1px] before:absolute before:left-0 lg:before:inset-x-0 before:bottom-[-65px] before:top-[90px] before:bg-black before:mx-auto relative pl-[30px] lg:pl-0">
                <div class="w-full lg:w-1/2">
                  <div class="flex flex-col items-start lg:items-end lg:pr-[30px]">
                    <p class="w-full lg:text-right text-black text-[18px] leading-[25px] pl-[10px] lg:pl-0 lg:mr-[-30px]">
                      Future holds . . .
                    </p>
                    <div class="flex items-center flex-row-reverse lg:flex-row lg:justify-end gap-[20px] lg:gap-[30px] lg:mr-[-98px] relative ml-[-47px] lg:ml-0">
                      <h3 class="text-[#F24822] text-[26px] leading-[40px] fontClash font-[600] lg:text-right">
                        Final Phase of Presale - A Momentous Milestone:
                      </h3>
                      <img
                        alt=""
                        loading="lazy"
                        width="36"
                        height="36"
                        decoding="async"
                        data-nimg="1"
                        src="../images/presaleA.66d39c9e.svg"
                        style={{ color: "transparent" }}
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-1/2 flex flex-col gap-[20px]">
                  <img
                    alt=""
                    loading="lazy"
                    width="477"
                    height="220"
                    decoding="async"
                    data-nimg="1"
                    class="w-full rounded-[25px]"
                    src="../images/future1.png"
                    style={{ color: "transparent" }}
                  />
                  <div class="flex flex-col gap-[25px] text-[20px] leading-[28px]">
                    <p>
                      As we approach the completion of our third and final
                      presale phase, we stand at the cusp of an exciting new
                      chapter. Selling out this phase is not just a financial
                      achievement; it's a testament to the trust and enthusiasm
                      of our community.
                    </p>
                    <p>
                      This milestone marks the end of the beginning for Pikamoon
                      and the start of a journey that promises to reshape the
                      landscape of play-to-earn gaming.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full flex gap-[30px] lg:gap-[100px] flex-col lg:flex-row-reverse before:content-[''] before:w-[1px] before:absolute before:left-0 lg:before:inset-x-0 before:bottom-[-65px] before:top-[90px] before:bg-black before:mx-auto relative pl-[30px] lg:pl-0">
                <div class="w-full lg:w-1/2">
                  <div class="flex flex-col items-start lg:pr-[30px]">
                    <div class="flex items-center justify-end gap-[20px] lg:gap-[30px] ml-[-46px] lg:ml-[-68px] flex-row-reverse relative">
                      <h3 class="text-[#F24822] text-[26px] leading-[40px] fontClash font-[600]">
                        Tier 1 Centralized Exchange Listings - Expanding Our
                        Reach:
                      </h3>
                      <img
                        alt=""
                        loading="lazy"
                        width="36"
                        height="36"
                        decoding="async"
                        data-nimg="1"
                        src="../images/tier1.55b68960.svg"
                        style={{ color: "transparent" }}
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-1/2 flex flex-col gap-[20px]">
                  <div class="flex flex-col gap-[5px] items-end">
                    <img
                      alt=""
                      loading="lazy"
                      width="433"
                      height="221"
                      decoding="async"
                      data-nimg="1"
                      class="w-full rounded-[25px]"
                      src="../images/timelinebanner2.png"
                      style={{ color: "transparent" }}
                    />
                    <a class="text-[14px] text-[#9EB0CB]" href="#">
                      Image Courtesy: https://www.bitmex.com/app/trade
                    </a>
                  </div>
                  <div class="flex flex-col gap-[25px] text-[20px] leading-[28px] lg:text-right">
                    <p>
                      Following the success of our presale, we are gearing up
                      for listings on multiple Tier 1 centralized exchanges.
                      These listings will enhance the visibility of $PIKA and
                      provide our investors with increased liquidity and trading
                      options.
                    </p>
                    <p>
                      It's a significant step towards making Pikamoon accessible
                      to a broader audience and solidifying our presence in the
                      cryptocurrency market.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full flex flex-col lg:flex-row gap-[30px] lg:gap-[100px] before:content-[''] before:w-[1px] before:absolute before:left-0 lg:before:inset-x-0 before:bottom-[-65px] before:top-[90px] before:bg-black before:mx-auto relative pl-[30px] lg:pl-0">
                <div class="w-full lg:w-1/2">
                  <div class="flex flex-col items-start lg:items-end lg:pr-[30px]">
                    <div class="flex flex-row-reverse lg:flex-row items-center justify-start lg:justify-end gap-[20px] lg:gap-[30px] ml-[-45px] lg:ml-0 lg:mr-[-98px] relative">
                      <h3 class="text-[#F24822] text-[26px] leading-[40px] fontClash font-[600] lg:text-right">
                        NFT Integration - The Key to the Pikaverse:{" "}
                      </h3>
                      <img
                        alt=""
                        loading="lazy"
                        width="36"
                        height="36"
                        decoding="async"
                        data-nimg="1"
                        src="../images/timeline3.fd70ffee.svg"
                        style={{ color: "transparent" }}
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-1/2 flex flex-col gap-[20px]">
                  <img
                    alt=""
                    loading="lazy"
                    width="287"
                    height="221"
                    decoding="async"
                    data-nimg="1"
                    class="w-auto rounded-[25px]"
                    src="../images/timelinebanner3.png"
                    style={{ color: "transparent" }}
                  />
                  <div class="flex flex-col gap-[25px] text-[20px] leading-[28px]">
                    <p>
                      The integration of our unique NFTs is one of the most
                      anticipated events on our roadmap. These NFTs are not just
                      collectibles but the cornerstone of the Pikamoon gaming
                      experience.
                    </p>
                    <p>
                      They will unlock new dimensions in the Pikaverse, allowing
                      players to fully engage with our play-to-earn model and
                      explore the vastness of our gaming world with unique
                      abilities and privileges.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full flex flex-col gap-[30px] lg:gap-[100px] lg:flex-row-reverse before:content-[''] before:w-[1px] before:absolute before:left-0 lg:before:inset-x-0 before:bottom-[-65px] before:top-[90px] before:bg-black before:mx-auto relative pl-[30px] lg:pl-0">
                <div class="w-full lg:w-1/2">
                  <div class="flex flex-col items-start lg:pr-[30px]">
                    <div class="flex items-center justify-end gap-[20px] lg:gap-[30px] ml-[-45px] lg:ml-[-68px] flex-row-reverse relative">
                      <h3 class="text-[#F24822] text-[26px] leading-[40px] fontClash font-[600]">
                        Multichain Wallet Integration - A Gateway to
                        Inclusivity:
                      </h3>
                      <img
                        alt=""
                        loading="lazy"
                        width="36"
                        height="36"
                        decoding="async"
                        data-nimg="1"
                        src="../images/timeline4.6512df0b.svg"
                        style={{ color: "transparent" }}
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-1/2 flex flex-col gap-[20px]">
                  <img
                    alt=""
                    loading="lazy"
                    width="397"
                    height="221"
                    decoding="async"
                    data-nimg="1"
                    class="w-full rounded-[25px]"
                    src="../images/timelinebanner4.png"
                    style={{ color: "transparent" }}
                  />
                  <div class="flex flex-col gap-[25px] text-[20px] leading-[28px] lg:text-right">
                    <p>
                      In our commitment to building a truly inclusive metaverse,
                      we will be integrating a multichain wallet system. This
                      innovation will enable the seamless integration of various
                      blockchain assets into Pikamoon.
                    </p>
                    <p>
                      By breaking down the barriers between different blockchain
                      ecosystems, we are paving the way for a more
                      interconnected and diverse gaming experience.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full flex flex-col lg:flex-row gap-[30px] lg:gap-[100px] before:content-[''] before:w-[1px] before:absolute before:left-0 lg:before:inset-x-0 before:bottom-[-65px] before:top-[90px] before:bg-black before:mx-auto relative pl-[30px] lg:pl-0">
                <div class="w-full lg:w-1/2">
                  <div class="flex flex-col items-start lg:items-end lg:pr-[30px]">
                    <div class="flex items-center min-h-[50px] flex-row-reverse lg:flex-row justify-end gap-[20px] lg:gap-[30px] ml-[-44px] lg:ml-0 lg:mr-[-98px] relative">
                      <h3 class="text-[#F24822] text-[26px] leading-[40px] fontClash font-[600] lg:text-right">
                        The Release of the Open World Metaverse - A New Era of
                        Gaming:{" "}
                      </h3>
                      <img
                        alt=""
                        loading="lazy"
                        width="36"
                        height="36"
                        decoding="async"
                        data-nimg="1"
                        src="../images/timeline5.140ed013.svg"
                        style={{ color: "transparent" }}
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-1/2 flex flex-col gap-[20px]">
                  <img
                    alt=""
                    loading="lazy"
                    width="513"
                    height="371"
                    decoding="async"
                    data-nimg="1"
                    class="w-full rounded-[25px]"
                    src="../images/timelinebanner5.png"
                    style={{ color: "transparent" }}
                  />
                  <div class="flex flex-col gap-[25px] text-[20px] leading-[28px]">
                    <p>
                      The launch of our open world metaverse is set to be a
                      groundbreaking event. This release will mark the
                      transition of Pikamoon from a concept to a living,
                      breathing universe.
                    </p>
                    <p>
                      The open world metaverse will offer an immersive gaming
                      experience where players can explore, interact, and create
                      in ways never before possible in the play-to-earn space.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full flex-col flex gap-[30px] lg:gap-[100px] lg:flex-row-reverse relative pl-[30px] lg:pl-0">
                <div class="w-full lg:w-1/2">
                  <div class="flex flex-col items-start lg:pr-[30px]">
                    <div class="flex items-start justify-end gap-[20px] lg:gap-[30px] ml-[-44px] lg:ml-[-68px] flex-row-reverse relative">
                      <h3 class="text-[#F24822] text-[26px] leading-[40px] fontClash font-[600]">
                        eSports Tournaments - A Platform for Competition and
                        Glory:{" "}
                      </h3>
                      <img
                        alt=""
                        loading="lazy"
                        width="36"
                        height="36"
                        decoding="async"
                        data-nimg="1"
                        src="../images/timeline6.92a822f9.svg"
                        style={{ color: "transparent" }}
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-1/2 flex flex-col gap-[20px]">
                  <img
                    alt=""
                    loading="lazy"
                    width="397"
                    height="221"
                    decoding="async"
                    data-nimg="1"
                    class="w-full rounded-[25px]"
                    src="../images/timelinebanner6.png"
                    style={{ color: "transparent" }}
                  />
                  <div class="flex flex-col gap-[25px] text-[20px] leading-[28px] lg:text-right">
                    <p>
                      eSports tournaments in Pikamoon are not just gaming
                      events; they are battlegrounds for glory.{" "}
                    </p>
                    <p>
                      These tournaments will offer players the opportunity to
                      showcase their skills, compete with the best, and earn
                      rewards. We envision creating a vibrant eSports ecosystem
                      that will become a cornerstone of the play-to-earn
                      community.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <a
            className="max-w-[600px] w-[80%] text-center h-[100px] flex justify-center items-center text-black fontClash font-[600] border-2 border-[#9EB0CB] rounded-[30px] text-[32px] leading-[39px] mx-auto my-[40px] relative z-2 bg-white"
            href="#"
          >
            A Look into the Future?
          </a>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default OurJourney;
