import React, { useState } from "react";
import classnames from "classnames";

import Footer from "../layout/Footer";
import Blog from "../layout/Blog";

const HomePage = () => {
  const [nftfilter, setNFtfilter] = useState("fire");

  return (
    <>
      <div>
        <div className="relative fon">
          <div className="max-w-[1226px] w-full mx-auto relative">
            <div className="absolute top-0 right-[0] flex justify-end z-[2] overflow-hidden">
              <a target="_blank" href="https://twitter.com/pikamooncoin">
                <img
                  alt="x"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/X.svg"
                />
              </a>
              <a target="_blank" href="https://t.me/pikamoonofficial">
                <img
                  alt="Telegram"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/Telegram.svg"
                />
              </a>
              <a target="_blank" href="https://discord.com/invite/QFHbbKQed9">
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/Discord.svg"
                />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/pikamooncoin_/"
              >
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/Instagram.svg"
                />
              </a>
              <a target="_blank" href="https://www.youtube.com/@pikamooncoin">
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/youtubeicon.svg"
                />
              </a>
              <a
                target="_blank"
                href="https://www.reddit.com/r/officialpikamoon/"
              >
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/reddit.svg"
                />
              </a>
            </div>
          </div>
          <div className="marquee text-[14px] text-white bg-black relative h-[42px] md:h-[50px] flex items-center overflow-hidden">
            <section>
              <div className="flex items-center flex-nowrap min-w-fit font-[700]">
                <a className=" min-w-fit fontEnigma" href="/buy">
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="../images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
              </div>
            </section>
          </div>
        </div>
        <div className="z-[9] co-navbar  px-[20px] h-[80px] border-b border-b-[2.5px] border-black text-black bg-gradient-to-r from-[#ffbc00] to-[#ff9100] hidden md:block sticky top-[-2px] bg-[#ffffff a-z-30">
          <div className="max-w-[1223px] w-full h-full mx-auto block md:flex items-center gap-x-2 flex-wrap md:flex-nowrap lg:gap-x-10">
            <div className="flex md:block items-center justify-between">
              <a href="/">
                <img
                  alt="close"
                  loading="lazy"
                  width="80"
                  height="80"
                  decoding="async"
                  data-nimg="1"
                  className="relative top-[15px] transition-transform ease-in-out z-[1] hover:scale-[1.2] transform origin-center will-change-transform"
                  style={{ color: "transparent" }}
                  src="/images/logo.svg"
                />
              </a>

              <a
                className=" custom-bg-hover hover:text-white relative flex md:hidden justify-center items-center w-[148px] h-[35px]"
                href="/token"
              >
                <svg
                  className="absolute left-0 right-0 top-0"
                  width="148"
                  height="35"
                  viewBox="0 0 148 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M148 15.4447C148 18.0797 146.96 20.6082 145.106 22.4806L135.645 32.036C133.767 33.9328 131.208 35 128.539 35L10 35C4.47716 35 -3.91405e-07 30.5228 -8.74228e-07 25L-2.18557e-06 10C-2.66839e-06 4.47715 4.47715 -3.91405e-07 10 -8.74228e-07L138 -1.20643e-05C143.523 -1.25472e-05 148 4.47714 148 9.99999L148 15.4447Z"
                    fill="white"
                  ></path>
                </svg>
                <span className=" text-[14px] relative z-[2] flex items-center gap-[10px]">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13L13 1.00001M13 1.00001L2.2 1.00001M13 1.00001L13 11.8"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  BUY $CryptoFun
                </span>
              </a>
            </div>

            <ul className="flex gap-[10px] lg:gap-[25px] items-center justify-center  md:justify-start bg-gradient-to-r from-[#ffbc00] to-[#ff9100] p-[15px] md:p-0 md:bg-none  ">
              <li>
                <a
                  className=" menu-link-effect text-[18px] lg:text-[20px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                  href="/play-beta"
                >
                  Play BETA{" "}
                  <span className="absolute right-[-14px] top-0 opacity-0">
                    <img
                      alt="Menu"
                      loading="lazy"
                      width="26"
                      height="40"
                      decoding="async"
                      data-nimg="1"
                      src="../images/menu-shape.png"
                      style={{ color: "transparent" }}
                    />
                  </span>{" "}
                </a>
              </li>

              <li>
                <a
                  className="false  menu-link-effect text-[18px] lg:text-[20px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                  href="/our-journey"
                >
                  Our Journey{" "}
                  <span className="absolute right-[-14px] top-0 opacity-0">
                    <img
                      alt="Menu"
                      loading="lazy"
                      width="26"
                      height="40"
                      decoding="async"
                      data-nimg="1"
                      src="../images/menu-shape.png"
                      style={{ color: "transparent" }}
                    />
                  </span>{" "}
                </a>
              </li>
            </ul>

            <div className="ml-auto flex items-center gap-x-1 lg:gap-x-2 2xl:gap-x-5" />
            <div>
              <a
                className=" custom-bg-hover hover:text-white relative hidden md:flex justify-center items-center w-[148px] h-[35px]"
                href="/token"
              >
                <img
                  alt="buyPikaArrow"
                  fetchpriority="high"
                  width="148"
                  height="35"
                  decoding="async"
                  data-nimg="1"
                  class=""
                  style={{ color: "transparent" }}
                  src="/images/buyPikaArrow.svg"
                />
              </a>
            </div>

            <p className="hidden md:block bg-transparent outline-0 font-semibold text-[18px] capitalize tracking-[-0.36px]">
              ENG
            </p>
          </div>
        </div>

        <input
          type="checkbox"
          class="hidden menu-active"
          id="Mobile-menu"
        ></input>
        <div class="mobile-header co-navbar px-[20px] h-auto text-black bg-gradient-to-r from-[#ffbc00] to-[#ff9100] block md:hidden relative z-[99] sticky top-[-2px]">
          <div class="flex items-center gap-x-2 flex-wrap md:flex-nowrap lg:gap-x-10">
            <a href="/">
              <img
                alt="close"
                fetchpriority="high"
                width="80"
                height="80"
                decoding="async"
                data-nimg="1"
                class="logo-postion hover:scale-[1.2] transtionHoverLogo z-[1]"
                style={{ color: "transparent" }}
                src="/images/logo.svg"
              />
            </a>
            <div class="ml-auto flex items-center gap-x-[22px] lg:gap-x-2 2xl:gap-x-5">
              <a href="/token">
                <img
                  alt="buyPikaArrow"
                  fetchpriority="high"
                  width="148"
                  height="35"
                  decoding="async"
                  data-nimg="1"
                  class=""
                  style={{ color: "transparent" }}
                  src="/images/buyPikaArrow.svg"
                />
              </a>
              <label for="Mobile-menu">
                <img
                  alt="Mobile Menu"
                  loading="lazy"
                  width="29"
                  height="29"
                  decoding="async"
                  data-nimg="1"
                  style={{ color: "transparent" }}
                  src="/images/Mobile-menu.5ef721ad.svg"
                  class="menu-mobile1"
                />
                <img
                  alt="Mobile Menu"
                  loading="lazy"
                  width="29"
                  height="29"
                  decoding="async"
                  data-nimg="1"
                  class="menu-mobile2"
                  style={{ color: "transparent" }}
                  src="/images/menu-close.2bb71f01.svg"
                />
              </label>
            </div>
          </div>
          <ul class="mobile-menu-list ease-out flex flex-col gap-[5px] w-full max-h-[0] overflow-hidden opacity-0">
            <li>
              <a
                class=" h-[40px] md:h-auto inline-flex menu-link-effect text-[18px] lg:text-[18px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                href="/play-beta"
              >
                Play Beta
                <span class="absolute right-[-14px] top-0 opacity-0">
                  <img
                    alt="Menu"
                    loading="lazy"
                    width="26"
                    height="40"
                    decoding="async"
                    data-nimg="1"
                    style={{ color: "transparent" }}
                    src="/images/menu-shape.2f1d76fd.png&amp;w=64&amp;q=75"
                  />
                </span>
              </a>
            </li>

            <li>
              <a
                class=" h-[40px] md:h-auto menu-link-effect inline-flex text-[18px] lg:text-[18px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                href="/our-journey"
              >
                Our Journey{" "}
                <span class="absolute right-[-14px] top-0 opacity-0">
                  <img
                    alt="Menu"
                    loading="lazy"
                    width="26"
                    height="40"
                    decoding="async"
                    data-nimg="1"
                    style={{ color: "transparent" }}
                    src="images/menu-shape.2f1d76fd.png"
                  />
                </span>
              </a>
            </li>
          </ul>
        </div>

        <section className="custom-hero-section w-full px-[30px] py-[70px] bg-[url('../images/New-hero-img.jpg')] bg-no-repeat h-[900px] md:h-[1200px] relative">
          <div className="flex justify-between items-center absolute top-[40px] left-0 right-0">
            <img
              alt="Cloud"
              loading="lazy"
              width="327"
              height="156"
              decoding="async"
              data-nimg="1"
              className="cloud-1 max-w-[40%]"
              src="../images/cloud-3.05115ebe.svg"
              style={{ color: "transparent", visibility: "visible" }}
              data-xblocker="passed"
            />
            <img
              alt="Cloud"
              loading="lazy"
              width="299"
              height="253"
              decoding="async"
              data-nimg="1"
              className="cloud-2 max-w-[40%]"
              src="../images/cloud-4.5f81391a.svg"
              style={{ color: "transparent", visibility: "visible" }}
              data-xblocker="passed"
            />
          </div>
          <div className="max-w-[730px] w-full flex flex-col gap-[22px] text-center mx-auto relative z-[2]">
            <h5 className="text-[#F24822] text-[16px] md:text-[30px] leading-[23px] md:leading-[32px] uppercase fontEnigma font-[700]]">
              $CryptoFun is now trading on
            </h5>
            <div className="flex gap-4 justify-center mt-[20px]">
              <a
                href="https://partner.bitget.com/bg/PIKAMOONxBITGET"
                target="_blank"
              >
                <img
                  alt="Header"
                  fetchpriority="high"
                  width="200"
                  height="100"
                  decoding="async"
                  data-nimg="1"
                  className=" w-[105px] md:w-[235px] hover:scale-[1.2] transition ease-in-out z-[1]"
                  src="../images/3.b446e78f.svg"
                  style={{ color: "transparent", visibility: "visible" }}
                  data-xblocker="passed"
                />
              </a>
              <a
                href="https://www.mexc.com/register?inviteCode=mexc-12ARBC"
                target="_blank"
              >
                <img
                  alt="Header"
                  fetchpriority="high"
                  width="201"
                  height="100"
                  decoding="async"
                  data-nimg="1"
                  className=" w-[105px] md:w-[235px] hover:scale-[1.2] transition ease-in-out z-[1]"
                  src="../images/2.94e10445.svg"
                  style={{ color: "transparent", visibility: "visible" }}
                  data-xblocker="passed"
                />
              </a>
              <a
                href="https://app.uniswap.org/swap?inputCurrency=eth&amp;outputCurrency=0xD1e64bcc904Cfdc19d0FABA155a9EdC69b4bcdAe"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Header"
                  fetchpriority="high"
                  width="200"
                  height="100"
                  decoding="async"
                  data-nimg="1"
                  className=" w-[105px] md:w-[235px] hover:scale-[1.2] transition ease-in-out z-[1]"
                  src="../images/1.5d51fefd.svg"
                  style={{ color: "transparent", visibility: "visible" }}
                  data-xblocker="passed"
                />
              </a>
            </div>
            <h6 className="max-w-[600px] mx-auto capitalize font-normal text-[20px] md:text-[28px] tracking-[-0.56px] pt-[22px]">
              $DOBI – The Fastest-Growing GameFi Token in the Industry
            </h6>
            <div className="pt-[40px] w-[240px] mx-auto">
              <a id="bgWhiteCoBlackBorderBlack" className="" href="/buy">
                <div className="relative">
                  <svg
                    width="240"
                    height="60"
                    fill="#F24822"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer transition duration-1000 ease-out text-[25px] mx-auto"
                  >
                    <path
                      d="M229.808 35.6904C231.222 34.0552 232 31.9658 232 29.8042L232 10C232 5.02944 227.971 1 223 1L9.99999 1.00002C5.02943 1.00002 0.999995 5.02946 0.999996 10L0.999999 50C1 54.9706 5.02943 59 9.99999 59L205.539 59C208.153 59 210.638 57.8636 212.347 55.8862L229.808 35.6904Z"
                      stroke="none"
                      stroke-width="2"
                    ></path>
                    <text x="50%" y="40" fill="white" text-anchor="middle">
                      BUY $CryptoFun
                    </text>
                  </svg>
                  <img
                    alt="Header"
                    fetchpriority="high"
                    width="240"
                    height="17"
                    decoding="async"
                    data-nimg="1"
                    className="absolute bottom-[-27px] z-[-1]"
                    src="../images/shadowImage.png"
                    style={{ color: "transparent" }}
                  />
                </div>
              </a>
            </div>
          </div>
        </section>

        <div className="max-w-[1106px] w-full mx-auto mt-[-50px] px-[30px]">
          <div className="w-full flex flex-col md:flex-row gap-[20px] lg:gap-[50px] justify-between">
            <div className="md:max-w-[265px] w-full md:w-1/3 z-10">
              <div className="w-full">
                <img
                  alt="hut"
                  loading="lazy"
                  width="250"
                  height="190"
                  decoding="async"
                  data-nimg="1"
                  className="transtionLogo h-[190px]"
                  src="/images/hut.png"
                  style={{ color: "transparent", visibility: "visible" }}
                  data-xblocker="passed"
                />
                <div className="w-full flex flex-col gap-[26px] mt-[20px]">
                  <h3 className="md:max-w-[220px] w-full text-[#F24822] text-[36px] leading-[48px] font-[600] tracking-[-2px]">
                    NFT Play-to-earn
                  </h3>
                  <p className="w-full text-[20px] leading-[28px] text-black">
                    Holders Of Pikamoon NFTs Gain Special Access To Competitive
                    ESports Arenas and Bespoke Experiences In The Dreva
                    Metaverse.
                  </p>
                  <a
                    className=" inline-block btnScale"
                    id="bgRedCoWhite"
                    href="/nfts"
                  >
                    <svg
                      width="240"
                      height="60"
                      fill="transparent"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer transition duration-1000 ease-out text-[20px] font-[600]"
                    >
                      <path
                        d="M229.808 35.6904C231.222 34.0552 232 31.9658 232 29.8042L232 10C232 5.02944 227.971 1 223 1L9.99999 1.00002C5.02943 1.00002 0.999995 5.02946 0.999996 10L0.999999 50C1 54.9706 5.02943 59 9.99999 59L205.539 59C208.153 59 210.638 57.8636 212.347 55.8862L229.808 35.6904Z"
                        stroke="black"
                        stroke-width="2"
                      ></path>
                      <text x="50%" y="35" fill="black" text-anchor="middle">
                        Begin With NFTs
                      </text>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div className="md:max-w-[295px] w-full md:w-1/3 md:pl-[30px] z-10">
              <div className="">
                <img
                  alt="close"
                  loading="lazy"
                  width="250"
                  height="190"
                  decoding="async"
                  data-nimg="1"
                  className="transtionLogo h-[190px]"
                  src="../images/fight.png"
                  style={{ color: "transparent", visibility: "visible" }}
                  data-xblocker="passed"
                />
                <div className="w-full mx-auto flex flex-col gap-[26px] mt-[20px]">
                  <h3
                    className="md:max-w-[220px] w-full text-[#F24822] text-[36px] leading-[48px] font-[600] tracking-[-2px]"
                    style={{ height: "100px" }}
                  >
                    Vast Pikaverse
                  </h3>
                  <p className="w-full text-[20px] leading-[28px] text-black">
                    Dreva: An Expansive, Dynamic World Offering Rich
                    Play-To-Earn Adventures And Player-Driven Creativity In The
                    Heart Of The Metaverse.
                  </p>
                  <a
                    className=" inline-block btnScale"
                    id="bgRedCoWhite"
                    href="/play-beta"
                  >
                    <svg
                      width="240"
                      height="60"
                      fill="transparent"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer transition duration-1000 ease-out text-[20px] font-[600]"
                    >
                      <path
                        d="M229.808 35.6904C231.222 34.0552 232 31.9658 232 29.8042L232 10C232 5.02944 227.971 1 223 1L9.99999 1.00002C5.02943 1.00002 0.999995 5.02946 0.999996 10L0.999999 50C1 54.9706 5.02943 59 9.99999 59L205.539 59C208.153 59 210.638 57.8636 212.347 55.8862L229.808 35.6904Z"
                        stroke="black"
                        stroke-width="2"
                      ></path>
                      <text x="50%" y="35" fill="black" text-anchor="middle">
                        Go to the game
                      </text>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div className="md:max-w-[265px] w-full md:w-1/3 z-10">
              <div className="">
                <img
                  alt="coins"
                  loading="lazy"
                  width="250"
                  height="190"
                  decoding="async"
                  data-nimg="1"
                  className="transtionLogo h-[190px]"
                  src="../images/coin.png"
                  style={{ color: "transparent", visibility: "visible" }}
                  data-xblocker="passed"
                />
                <div className="w-full flex flex-col gap-[26px] mt-[20px]">
                  <h3 className="md:max-w-[220px] w-full text-[#F24822] text-[36px] leading-[48px] font-[600] tracking-[-2px]">
                    Rewards - $CryptoFun
                  </h3>
                  <p className="w-full text-[20px] leading-[28px] text-black">
                    $CryptoFun Drives Pikamoon's Play-To-Earn Model, Offering
                    Players A Tangible Way To Earn, Spend, And Invest Within The
                    Game's Economy.
                  </p>
                  <a
                    className=" inline-block btnScale"
                    id="bgRedCoWhite"
                    href="/token"
                  >
                    <svg
                      width="240"
                      height="60"
                      fill="transparent"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer transition duration-1000 ease-out text-[20px] font-[600]"
                    >
                      <path
                        d="M229.808 35.6904C231.222 34.0552 232 31.9658 232 29.8042L232 10C232 5.02944 227.971 1 223 1L9.99999 1.00002C5.02943 1.00002 0.999995 5.02946 0.999996 10L0.999999 50C1 54.9706 5.02943 59 9.99999 59L205.539 59C208.153 59 210.638 57.8636 212.347 55.8862L229.808 35.6904Z"
                        stroke="black"
                        stroke-width="2"
                      ></path>
                      <text x="50%" y="35" fill="black" text-anchor="middle">
                        View Rewards
                      </text>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[url('/images/bg-gradient.svg')] partner-bg-size  md:partner-bg-size-md bg-repeat-x bg-center-top">
          <div className="relative">
            <div className="absolute top-[60px] bottom-0 left-0 right-0 !pointer-events-none">
              <div className="flex justify-between items-center lg:sticky left-0 right-0 top-[0]">
                <img
                  alt="Cloud"
                  loading="lazy"
                  width="274"
                  height="152"
                  decoding="async"
                  data-nimg="1"
                  className="cloud-1 max-w-[40%]"
                  src="/images/cloud-1.429b1c46.svg"
                  style={{ color: "transparent" }}
                />
                <img
                  alt="Cloud"
                  loading="lazy"
                  width="319"
                  height="160"
                  decoding="async"
                  data-nimg="1"
                  className="cloud-2 max-w-[40%]"
                  src="/images/cloud-2.73cacac8.svg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="flex justify-between items-center lg:sticky left-0 right-0 top-[450px] mt-[450px]">
                <img
                  alt="Cloud"
                  loading="lazy"
                  width="327"
                  height="156"
                  decoding="async"
                  data-nimg="1"
                  className="cloud-3 max-w-[40%]"
                  src="/images/cloud-3.05115ebe.svg"
                  style={{ color: "transparent" }}
                />
                <img
                  alt="Cloud"
                  loading="lazy"
                  width="299"
                  height="253"
                  decoding="async"
                  data-nimg="1"
                  className="cloud-4 max-w-[40%]"
                  src="/images/cloud-4.5f81391a.svg"
                  style={{ color: "transparent" }}
                />
              </div>
            </div>
            <section
              class="w-full relative z-[1] pt-[80px] pb-4"
              id="desktopPartnerLogo"
            >
              <div class="flex flex-col justify-center items-center">
                <h3 class="text-[32px] mb-[40px] font-semibold fontClash">
                  Audited by
                </h3>
                <a
                  target="_blank"
                  class="audd"
                  href="https://hacken.io/audits/pikamoon/"
                ></a>
              </div>
              <h3 class="text-center text-[32px] mt-[45px] mb-[53px] font-semibold fontClash">
                Partners
              </h3>

              <div class="hidden md:block">
                <div class="flex justify-center items-center gap-x-10 mt-[50px]">
                  <span class="iconSvgFirstRow Kervru"></span>
                  <span class="iconSvgFirstRow bloomberg"></span>
                  <span class="iconSvgFirstRow yahoo"></span>
                </div>
                <div class="flex justify-center items-center gap-x-[29px] mt-[40px]">
                  <span class="iconSvgSecondRow outlook"></span>
                  <span class="iconSvgSecondRow space"></span>
                  <span class="iconSvgSecondRow blocmail"></span>
                  <span class="iconSvgSecondRow eth"></span>
                </div>
                <div class="flex justify-center items-center gap-x-[29px] mt-[45px]">
                  <span class="iconSvgThirdRow newsBTC"></span>
                  <span class="iconSvgThirdRow analytics"></span>
                  <span class="iconSvgThirdRow crypto"></span>
                  <span class="iconSvgThirdRow cnews"></span>
                </div>
                <div class="flex justify-center items-center gap-x-[46px] mt-[35px]">
                  <span class="iconSvgForthRow bitget"></span>
                  <span class="iconSvgForthRow coin"></span>
                  <span class="iconSvgForthRow cnewsFlash"></span>
                  <span class="iconSvgForthRow tc"></span>
                  <span class="iconSvgForthRow unis"></span>
                </div>
                <div class="flex justify-center items-center gap-x-[46px] mt-[40px]">
                  <span class="iconSvgForthRow mexc"></span>
                </div>
              </div>

              <div class="block md:hidden">
                <div class="flex justify-center items-center gap-x-[31px] mt-[50px]">
                  <span class="iconSvgFirstRow Kervru"></span>
                  <span class="iconSvgFirstRow bloomberg"></span>
                  <span class="iconSvgFirstRow yahoo"></span>
                </div>
                <div class="flex justify-center items-center gap-x-[14px] mt-[30px]">
                  <span class="iconSvgSecondRow outlook"></span>
                  <span class="iconSvgSecondRow space"></span>
                  <span class="iconSvgSecondRow blocmail"></span>
                </div>
                <div class="flex justify-center items-center gap-x-[14px] mt-[33px]">
                  <span class="iconSvgSecondRow eth"></span>
                  <span class="iconSvgThirdRow newsBTC"></span>
                  <span class="iconSvgThirdRow analytics"></span>
                </div>
                <div class="flex justify-center items-center gap-x-[21px] mt-[25px]">
                  <span class="iconSvgThirdRow crypto"></span>
                  <span class="iconSvgThirdRow cnews"></span>
                  <span class="iconSvgForthRow coin"></span>
                </div>
                <div class="flex justify-center items-center gap-x-[21px] mt-[25px]">
                  <span class="iconSvgForthRow bitget"></span>
                  <span class="iconSvgForthRow cnewsFlash"></span>
                  <span class="iconSvgForthRow tc"></span>
                </div>
                <div class="flex justify-center items-center gap-x-[47px] mt-[20px]">
                  <span class="iconSvgForthRow unis"></span>
                  <span class="iconSvgForthRow mexc"></span>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="bgSky relative z-[1]">
          <div className="max-w-[1046px] w-full mx-auto px-[30px] py-[40px] flex justify-center">
            <iframe
              width="100%"
              height="474"
              src="https://www.youtube.com/embed/ZF-2w41Z4WQ?autoplay=1&amp;mute=1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              className="rounded-md md:rounded-3xl w-full h-[300px] md:h-[474px]"
            ></iframe>
          </div>
        </div>

        <div className="bgSky">
          <div className="relative">
            <div className="absolute top-[60px] bottom-0 left-0 right-0 !pointer-events-none">
              <div className="flex justify-between items-center lg:sticky left-0 right-0 top-[0]">
                <img
                  alt="Cloud"
                  loading="lazy"
                  width="274"
                  height="152"
                  decoding="async"
                  data-nimg="1"
                  className="cloud-1 max-w-[40%]"
                  src="../images/cloud-1.429b1c46.svg"
                  style={{ color: "transparent" }}
                />
                <img
                  alt="Cloud"
                  loading="lazy"
                  width="319"
                  height="160"
                  decoding="async"
                  data-nimg="1"
                  className="cloud-2 max-w-[40%]"
                  src="../images/cloud-2.73cacac8.svg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="flex justify-between items-center lg:sticky left-0 right-0 top-[450px] mt-[450px]">
                <img
                  alt="Cloud"
                  loading="lazy"
                  width="327"
                  height="156"
                  decoding="async"
                  data-nimg="1"
                  className="cloud-3 max-w-[40%]"
                  src="../images/cloud-3.05115ebe.svg"
                  style={{ color: "transparent" }}
                />
                <img
                  alt="Cloud"
                  loading="lazy"
                  width="299"
                  height="253"
                  decoding="async"
                  data-nimg="1"
                  className="cloud-4 max-w-[40%]"
                  src="../images/cloud-4.5f81391a.svg"
                  style={{ color: "transparent" }}
                />
              </div>
            </div>
            <section className="max-w-[1226px] w-full mx-auto z-[2] relative min-h-[780px]">
              <h1 className="text-center text-[32px] font-bold fontClash">
                Character’s NFTs
              </h1>
              <p className="mx-auto w-[82%] py-4 text-center font-normal text-[18px] md:text-[20px] capitalize leading-[143.25%] tracking-[-0.4px]">
                In Pikamoon, each NFT card embodies one of six elemental forces,
                offering unique abilitiesand strengths that shape your strategy
                and gameplay in the dynamic world.
              </p>
              <div>
                <div className="flex items-center sm:justify-center gap-x-[43px] pt-[40px] fontClash overflow-auto hideScrollbar px-[30px] mb-6">
                  <div
                    className={classnames(
                      {
                        "text-[#F24822] font-[700] border-[#f24822] border-b-[4px] fontClash":
                          nftfilter == "fire",
                      },
                      "co-gray fontClash text-[24px] leading-[24px] text-[#9EB0CB] py-[10px] cursor-pointer"
                    )}
                    onClick={() => setNFtfilter("fire")}
                  >
                    Fire
                  </div>
                  <div
                    className={classnames(
                      {
                        "text-[#F24822] font-[700] border-[#f24822] border-b-[4px] fontClash":
                          nftfilter == "water",
                      },
                      "co-gray fontClash text-[24px] leading-[24px] text-[#9EB0CB] py-[10px] cursor-pointer"
                    )}
                    onClick={() => setNFtfilter("water")}
                  >
                    Water
                  </div>
                  <div
                    className={classnames(
                      {
                        "text-[#F24822] font-[700] border-[#f24822] border-b-[4px] fontClash":
                          nftfilter == "electric",
                      },
                      "co-gray fontClash text-[24px] leading-[24px] text-[#9EB0CB] py-[10px] cursor-pointer"
                    )}
                    onClick={() => setNFtfilter("electric")}
                  >
                    Electric
                  </div>
                  <div
                    className={classnames(
                      {
                        "text-[#F24822] font-[700] border-[#f24822] border-b-[4px] fontClash":
                          nftfilter == "rock",
                      },
                      "co-gray fontClash text-[24px] leading-[24px] text-[#9EB0CB] py-[10px] cursor-pointer"
                    )}
                    onClick={() => setNFtfilter("rock")}
                  >
                    Rock
                  </div>
                  <div
                    className={classnames(
                      {
                        "text-[#F24822] font-[700] border-[#f24822] border-b-[4px] fontClash":
                          nftfilter == "air",
                      },
                      "co-gray fontClash text-[24px] leading-[24px] text-[#9EB0CB] py-[10px] cursor-pointer"
                    )}
                    onClick={() => setNFtfilter("air")}
                  >
                    Air
                  </div>
                  <div
                    className={classnames(
                      {
                        "text-[#F24822] font-[700] border-[#f24822] border-b-[4px] fontClash":
                          nftfilter == "earth",
                      },
                      "co-gray fontClash text-[24px] leading-[24px] text-[#9EB0CB] py-[10px] cursor-pointer"
                    )}
                    onClick={() => setNFtfilter("earth")}
                  >
                    Earth
                  </div>
                </div>
                <div className="w-full">
                  <div
                    id="tab1"
                    className={classnames(
                      { active: nftfilter == "fire" },
                      "tab-content overflow-x-auto hideScrollbar  px-[30px] overflow-auto"
                    )}
                  >
                    {nftfilter == "fire" && (
                      <div className="animation-box-group flex md:justify-center gap-4 pb-[40px]  py-4">
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Fire card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Fire (1).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Fire card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Fire (2).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Fire card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Fire (3).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    id="tab2"
                    className="active tab-content overflow-x-auto hideScrollbar  px-[30px] overflow-auto"
                  >
                    {nftfilter == "water" && (
                      <div
                        className={classnames(
                          { hidden: nftfilter != "water" },
                          "animation-box-group flex md:justify-center gap-4 pb-[40px]  py-4"
                        )}
                      >
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Water card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Water (1).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Water card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Water (2).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Water card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Water (3).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    id="tab3"
                    className="tab-content overflow-x-auto hideScrollbar  px-[30px] overflow-auto  "
                  >
                    {nftfilter == "electric" && (
                      <div className="animation-box-group flex md:justify-center gap-4 pb-[40px]  py-4">
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Electric card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Electric (1).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Electric card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Electric (2).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Electric card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Electric (3).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    id="tab4"
                    className="tab-content overflow-x-auto hideScrollbar  px-[30px] overflow-auto  "
                  >
                    {nftfilter == "rock" && (
                      <div className="animation-box-group flex md:justify-center gap-4 pb-[40px]  py-4">
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Rock card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Rock (1).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Rock card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Rock (2).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Rock card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Rock (3).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    id="tab5"
                    className="tab-content overflow-x-auto hideScrollbar  px-[30px] overflow-auto  "
                  >
                    {nftfilter == "air" && (
                      <div className="animation-box-group flex md:justify-center gap-4 pb-[40px]  py-4">
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Air card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Air (1).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Air card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Air (2).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Air card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Air (3).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    id="tab6"
                    className="tab-content overflow-x-auto hideScrollbar  px-[30px] overflow-auto  "
                  >
                    {nftfilter == "earth" && (
                      <div className="animation-box-group flex md:justify-center gap-4 pb-[40px]  py-4">
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Earth card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Earth (1).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Earth card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Earth (2).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                        <div className="min-w-[242px] game-box relative hover:top-[-10px] top-[-1px]">
                          <img
                            alt="Earth card"
                            loading="lazy"
                            width="242"
                            height="336"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="../images/Earth (3).png"
                            style={{ color: "transparent" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mx-auto w-[240px] mt-[65px] mb-[100px] z-[99999]">
                  <a id="bgRedCoWhite" href="/nfts ">
                    <svg
                      width="240"
                      height="60"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer transition duration-1000 ease-out text-[20px] cursor-pointer "
                    >
                      <path
                        d="M229.619 35.5269C230.994 33.9371 231.75 31.9057 231.75 29.8042L231.75 9.99998C231.75 5.16749 227.832 1.24998 223 1.24998L9.99999 1.25C5.1675 1.25 1.25 5.16751 1.25 10L1.25 50C1.25 54.8325 5.1675 58.75 9.99999 58.75L205.539 58.75C208.08 58.75 210.496 57.6451 212.158 55.7227L229.619 35.5269Z"
                        stroke="black"
                        stroke-width="2"
                      ></path>
                      <text x="50%" y="35" fill="black" text-anchor="middle">
                        Explore NFTs{" "}
                      </text>
                    </svg>
                  </a>
                </div>
              </div>
            </section>
          </div>
          {/* <div className="bgSky relative z-[1]">
            <div className="max-w-[1046px] w-full mx-auto px-[30px] py-[40px] flex justify-center">
              <iframe
                width="100%"
                height="474"
                src="https://www.youtube.com/embed/ZF-2w41Z4WQ?autoplay=1&amp;mute=1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                className="rounded-md md:rounded-3xl w-full h-[300px] md:h-[474px]"
              ></iframe>
            </div>
          </div> */}
        </div>

        <Blog />

        <Footer />
      </div>
    </>
  );
};

export default HomePage;
