import { Routes, Route, BrowserRouter } from "react-router-dom";

import './App.css';

import { Provider } from "react-redux";
import store from "./store";
import HomePage from "./pages/Home";
import Token from "./pages/Token";
import PlayBeta from "./pages/PlayBeta";
import OurJourney from "./pages/OurJourney";

import "react-toastify/dist/ReactToastify.css";

import { Web3ModalProvider } from "./components/Web3ModalProvider";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <Web3ModalProvider>
      <Provider store={store}>
      <ToastContainer/>
          <BrowserRouter>
            <Routes>
              
              <Route path="/" element={<HomePage />}>
              </Route>
              <Route path="/token" element={<Token />}>
              </Route>
              <Route path="/play-beta" element={<PlayBeta />}>
              </Route>
              <Route path="/our-journey" element={<OurJourney />}>
              </Route>
            </Routes>
          </BrowserRouter>
        <ToastContainer />
      </Provider>
    </Web3ModalProvider>
  );
};

export default App;
