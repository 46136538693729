import React, { useState } from "react";
import classnames from "classnames";

import Footer from "../layout/Footer";
// import Blog from "../layout/Blog";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const PlayBeta = () => {
  const [betStatus, setBetaStatus] = useState("story");
  const [system, setSystem] = useState("android");

  return (
    <>
      <div>
        <div className="relative fon">
          <div className="max-w-[1226px] w-full mx-auto relative">
            <div className="absolute top-0 right-[0] flex justify-end z-[2] overflow-hidden">
              <a target="_blank" href="https://twitter.com/pikamooncoin">
                <img
                  alt="x"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/X.svg"
                />
              </a>
              <a target="_blank" href="https://t.me/pikamoonofficial">
                <img
                  alt="Telegram"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/Telegram.svg"
                />
              </a>
              <a target="_blank" href="https://discord.com/invite/QFHbbKQed9">
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/Discord.svg"
                />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/pikamooncoin_/"
              >
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/Instagram.svg"
                />
              </a>
              <a target="_blank" href="https://www.youtube.com/@pikamooncoin">
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/youtubeicon.svg"
                />
              </a>
              <a
                target="_blank"
                href="https://www.reddit.com/r/officialpikamoon/"
              >
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/reddit.svg"
                />
              </a>
            </div>
          </div>
          <div className="marquee text-[14px] text-white bg-black relative h-[42px] md:h-[50px] flex items-center overflow-hidden">
            <section>
              <div className="flex items-center flex-nowrap min-w-fit font-[700]">
                <a className=" min-w-fit fontEnigma" href="/buy">
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="../images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
              </div>
            </section>
          </div>
        </div>
        <div className="z-[9] co-navbar  px-[20px] h-[80px] border-b border-b-[2.5px] border-black text-black bg-gradient-to-r from-[#ffbc00] to-[#ff9100] hidden md:block sticky top-[-2px] bg-[#ffffff a-z-30">
          <div className="max-w-[1223px] w-full h-full mx-auto block md:flex items-center gap-x-2 flex-wrap md:flex-nowrap lg:gap-x-10">
            <div className="flex md:block items-center justify-between">
              <a href="/">
                <img
                  alt="close"
                  loading="lazy"
                  width="80"
                  height="80"
                  decoding="async"
                  data-nimg="1"
                  className="relative top-[15px] transition-transform ease-in-out z-[1] hover:scale-[1.2] transform origin-center will-change-transform"
                  style={{ color: "transparent" }}
                  src="/images/logo.svg"
                />
              </a>

              <a
                className=" custom-bg-hover hover:text-white relative flex md:hidden justify-center items-center w-[148px] h-[35px]"
                href="/token"
              >
                 <img
                  alt="buyPikaArrow"
                  fetchpriority="high"
                  width="148"
                  height="35"
                  decoding="async"
                  data-nimg="1"
                  class=""
                  style={{ color: "transparent" }}
                  src="/images/buyPikaArrow.svg"
                />
              </a>
            </div>

            <ul className="flex gap-[10px] lg:gap-[25px] items-center justify-center  md:justify-start bg-gradient-to-r from-[#ffbc00] to-[#ff9100] p-[15px] md:p-0 md:bg-none  ">
              <li>
                <a
                  className="bg-black menu-link-effect text-[18px] lg:text-[20px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                  href="/play-beta"
                >
                  Play BETA{" "}
                  <span className="absolute right-[-14px] top-0 opacity-0">
                    <img
                      alt="Menu"
                      loading="lazy"
                      width="26"
                      height="40"
                      decoding="async"
                      data-nimg="1"
                      src="../images/menu-shape.png"
                      style={{ color: "transparent" }}
                    />
                  </span>{" "}
                </a>
              </li>

              <li>
                <a
                  className="false menu-link-effect text-[18px] lg:text-[20px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                  href="/our-journey"
                >
                  Our Journey{" "}
                  <span className="absolute right-[-14px] top-0 opacity-0">
                    <img
                      alt="Menu"
                      loading="lazy"
                      width="26"
                      height="40"
                      decoding="async"
                      data-nimg="1"
                      src="../images/menu-shape.png"
                      style={{ color: "transparent" }}
                    />
                  </span>{" "}
                </a>
              </li>
            </ul>

            <div className="ml-auto flex items-center gap-x-1 lg:gap-x-2 2xl:gap-x-5" />
            <div>
              <a
                className=" custom-bg-hover hover:text-white relative hidden md:flex justify-center items-center w-[148px] h-[35px]"
                href="/token"
              >
                 <img
                  alt="buyPikaArrow"
                  fetchpriority="high"
                  width="148"
                  height="35"
                  decoding="async"
                  data-nimg="1"
                  class=""
                  style={{ color: "transparent" }}
                  src="/images/buyPikaArrow.svg"
                />
              </a>
            </div>

            <p className="hidden md:block bg-transparent outline-0 font-semibold text-[18px] capitalize tracking-[-0.36px]">
              ENG
            </p>
          </div>
        </div>

        <input type="checkbox" class="hidden menu-active" id="Mobile-menu"></input>
        <div class="mobile-header co-navbar px-[20px] h-auto text-black bg-gradient-to-r from-[#ffbc00] to-[#ff9100] block md:hidden relative z-[99] sticky top-[-2px]" >
          <div class="flex items-center gap-x-2 flex-wrap md:flex-nowrap lg:gap-x-10">
            <a href="/">
              <img
                alt="close"
                fetchpriority="high"
                width="80"
                height="80"
                decoding="async"
                data-nimg="1"
                class="logo-postion hover:scale-[1.2] transtionHoverLogo z-[1]"
                style={{ color: "transparent" }}
                src="/images/logo.svg"
              />
            </a>
            <div class="ml-auto flex items-center gap-x-[22px] lg:gap-x-2 2xl:gap-x-5">
              <a href="/token">
                <img
                  alt="buyPikaArrow"
                  fetchpriority="high"
                  width="148"
                  height="35"
                  decoding="async"
                  data-nimg="1"
                  class=""
                  style={{ color: "transparent" }}
                  src="/images/buyPikaArrow.svg"
                />
              </a>
              <label for="Mobile-menu">
                <img
                  alt="Mobile Menu"
                  loading="lazy"
                  width="29"
                  height="29"
                  decoding="async"
                  data-nimg="1"
                  style={{ color: "transparent" }}
                  src="/images/Mobile-menu.5ef721ad.svg"
                  class="menu-mobile1"
                />
                <img
                  alt="Mobile Menu"
                  loading="lazy"
                  width="29"
                  height="29"
                  decoding="async"
                  data-nimg="1"
                  class="menu-mobile2"
                  style={{ color: "transparent" }}
                  src="/images/menu-close.2bb71f01.svg"
                />
              </label>
            </div>
          </div>
          <ul class="mobile-menu-list ease-out flex flex-col gap-[5px] w-full max-h-[0] overflow-hidden opacity-0" >
         
            <li>
              <a
                class="bg-black h-[40px] md:h-auto inline-flex menu-link-effect text-[18px] lg:text-[18px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                href="/play-beta"
              >
                Play Beta
                <span class="absolute right-[-14px] top-0 opacity-0">
                  <img
                    alt="Menu"
                    loading="lazy"
                    width="26"
                    height="40"
                    decoding="async"
                    data-nimg="1"
                    style={{ color: "transparent" }}
                    
                    src="/images/menu-shape.2f1d76fd.png&amp;w=64&amp;q=75"
                  />
                </span>
              </a>
            </li>
         
            <li>
              <a
                class="h-[40px] md:h-auto menu-link-effect inline-flex text-[18px] lg:text-[18px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                href="/our-journey"
              >
                Our Journey{" "}
                <span class="absolute right-[-14px] top-0 opacity-0">
                  <img
                    alt="Menu"
                    loading="lazy"
                    width="26"
                    height="40"
                    decoding="async"
                    data-nimg="1"
                    style={{ color: "transparent" }}
                   
                    src="images/menu-shape.2f1d76fd.png"
                  />
                </span>
              </a>
            </li>
          </ul>
        </div>

        <div className="relative z-[0]  overflow-hidden banner-bottom-shadow rounded-[0_0_30px_30px]">
          <img
            alt="gamelanding"
            fetchpriority="high"
            width="1200"
            height="500"
            decoding="async"
            data-nimg="1"
            className="w-full h-[255px] md:h-[300px] object-cover md:object-cover rounded-br-[30px] rounded-bl-[30px]"
            src="../images/play-beta-header.png"
            style={{ color: "transparent" }}
          />
          <div className="absolute inset-0 bg-black opacity-25 rounded-br-[30px] rounded-bl-[30px]"></div>
          <p className="absolute w-[80%] md:w-[90%] bottom-[20px] md:bottom-[40px] xl:bottom-[40px] left-[50%] transform translate-x-[-50%] text-center font-semibold co-white text-[16px] md:text-[20px]">
            Test out the Pikamoon Beta and get a glimpse into the future of
            gaming.
          </p>
        </div>

        <div className="pt-[40px] px-[30px] bg-[url('../images/play-game-bg.png')]  bg-contain bg-repeat bg-center-top relative min-h-[1000px]">
          <div className="max-w-[1226px] w-full mx-auto relative z-[2]">
            <div className="grid grid-cols-12 md:gap-[35px]">
              <div className="col-span-12 md:col-span-12 lg:col-span-9 ">
                <div className="flex flex-row md:flex-row sm:flex-row gap-y-10 sm:gap-y-0 gap-x-5 md:gap-x-10 mb-[30px] md:mb-[0px]">
                  <img
                    alt="close"
                    fetchpriority="high"
                    width="120"
                    height="120"
                    decoding="async"
                    data-nimg="1"
                    className="bg-black max-w-fit rounded-[15px] p-4  md:p-2"
                    src="/images/logo.svg"
                    style={{ color: "transparent" }}
                  />
                  <div>
                    <h1 className="md: text-[22px] orangeTextClash">
                      Pikamoon
                    </h1>
                    <p className=" text-[14px] md:text-[20px] font-semibold">
                      Six Elemental Regions in One Open-World Metaverse
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-12 lg:col-span-3 mobDisplayHide">
                <div className="flex flex-col gap-y-5 ">
                  <button className="hover:opacity-[0.8]">
                    <img
                      alt="close"
                      fetchpriority="high"
                      width="300"
                      height="200"
                      decoding="async"
                      data-nimg="1"
                      className="max-w-[252px] h-auto"
                      src="/images/playNow.svg"
                      style={{ color: "transparent" }}
                    />
                  </button>
                </div>
              </div>
              <div className="col-span-12 md:col-span-12 lg:col-span-9 pt-[20px]">
                <Carousel autoPlay infiniteLoop swipeable>
                  <div>
                    <img src="images/slide1.png" />
                    {/* <p className="legend">Legend 1</p> */}
                  </div>
                  <div>
                    <img src="images/slide2.png" />
                    {/* <p className="legend">Legend 1</p> */}
                  </div>
                  <div>
                    <img src="images/slide3.png" />
                    {/* <p className="legend">Legend 1</p> */}
                  </div>
                  <div>
                    <img src="images/slide4.png" />
                    {/* <p className="legend">Legend 1</p> */}
                  </div>
                  <div>
                    <img src="images/slide5.png" />
                    {/* <p className="legend">Legend 1</p> */}
                  </div>
                </Carousel>
              </div>

              <div className="col-span-12 md:col-span-12 lg:col-span-3">
                <div className="w-full h-auto bg-white rounded-[15px] ">
                  <h1 className="blackTextClash text-[24px] mx-6 pt-10">
                    Game Details
                  </h1>
                  <p className="text-[16px] font-normal mx-6">
                    Explore and discover new Pikamoon species, find hidden
                    treasures, and battle in online and offline modes across the
                    expansive Pikaverse.
                  </p>
                  <p className="text-[16px] font-medium mx-6 mt-4 mb-2 co-gray">
                    Genre:
                  </p>
                  <p className="text-[16px] font-normal mx-6">
                    Battle, Multiregional Arcade, Strategy, Evolution.
                  </p>
                  <p className="text-[16px] font-medium mx-6 mt-4 mb-2 co-gray">
                    Rating:
                  </p>
                  <div className="flex gap-x-3">
                    <p className="text-[16px] font-normal ml-6">4.5/5</p>
                    <img
                      alt="close"
                      loading="lazy"
                      width="100"
                      height="40"
                      decoding="async"
                      data-nimg="1"
                      className=""
                      src="/images/fiveStar.svg"
                      style={{ color: "transparent" }}
                    />
                  </div>
                  <p className="text-[16px] font-medium mx-6 mt-4 mb-2 co-gray">
                    Developed by:
                  </p>
                  <p className="text-[20px] font-medium mx-6">KeVuru Games</p>
                  <div className="flex gap-x-6 pt-6 pb-10">
                    <img
                      alt="close"
                      loading="lazy"
                      width="40"
                      height="40"
                      decoding="async"
                      data-nimg="1"
                      className="ml-6 "
                      src="/images/android.svg"
                      style={{ color: "transparent" }}
                    />
                    <img
                      alt="close"
                      loading="lazy"
                      width="40"
                      height="40"
                      decoding="async"
                      data-nimg="1"
                      className="ml-6"
                      src="/images/ios.svg"
                      style={{ color: "transparent" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-12 lg:col-span-12 DesktopDisplayHide mt-[20px]">
                <div className="mt-4 mb-20">
                  <div className="ant-tabs ant-tabs-top ant-tabs-card gameTab1  css-1b0bdye">
                    <div role="tablist" className="ant-tabs-nav">
                      <div className="ant-tabs-nav-wrap">
                        <div
                          className="ant-tabs-nav-list block md:flex relative"
                          style={{ transform: "translate(0px, 0px)" }}
                        >
                          <div
                            data-node-key="1"
                            className="ant-tabs-tab ant-tabs-tab-active mr-[5px]"
                          >
                            <div
                              role="tab"
                              aria-selected="true"
                              className={classnames(
                                {
                                  "text-[#F24822] font-[700] border border-black":
                                    betStatus == "story",
                                },
                                "cursor-pointer  bg-white  font-[700] border  text-[24px] md:text-[28px] w-full p-[10px_20px] rounded-[10px] whitespace-nowrap"
                              )}
                              onClick={() => {
                                setBetaStatus("story");
                              }}
                              tabindex="0"
                              id="rc-tabs-3-tab-1"
                              aria-controls="rc-tabs-3-panel-1"
                            >
                              The Story
                            </div>
                          </div>
                          <div
                            data-node-key="2"
                            className="ant-tabs-tab mr-[5px]"
                          >
                            <div
                              className={classnames(
                                {
                                  "text-[#F24822] font-[700] border border-black":
                                    betStatus == "factions",
                                },
                                "cursor-pointer  bg-white  font-[700] border  text-[24px] md:text-[28px] w-full p-[10px_20px] rounded-[10px] whitespace-nowrap"
                              )}
                              onClick={() => {
                                setBetaStatus("factions");
                              }}
                              role="tab"
                              aria-selected="false"
                              tabindex="0"
                              id="rc-tabs-3-tab-2"
                              aria-controls="rc-tabs-3-panel-2"
                            >
                              Factions
                            </div>
                          </div>
                          <div
                            data-node-key="3"
                            className="ant-tabs-tab mr-[5px]"
                          >
                            <div
                              role="tab"
                              aria-selected="false"
                              className={classnames(
                                {
                                  "text-[#F24822] font-[700] border border-black":
                                    betStatus == "collectibles",
                                },
                                "cursor-pointer  bg-white  font-[700] border  text-[24px] md:text-[28px] w-full p-[10px_20px] rounded-[10px] whitespace-nowrap"
                              )}
                              onClick={() => {
                                setBetaStatus("collectibles");
                              }}
                              tabindex="0"
                              id="rc-tabs-3-tab-3"
                              aria-controls="rc-tabs-3-panel-3"
                            >
                              Collectibles
                            </div>
                          </div>
                          <div
                            className="ant-tabs-ink-bar ant-tabs-ink-bar-animated"
                            style={{
                              width: "0px",
                              left: "0px",
                              transform: "translateX(-50%)",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="ant-tabs-nav-operations ant-tabs-nav-operations-hidden">
                        <button
                          type="button"
                          className="ant-tabs-nav-more"
                          tabindex="-1"
                          aria-hidden="true"
                          aria-haspopup="listbox"
                          aria-controls="rc-tabs-3-more-popup"
                          id="rc-tabs-3-more"
                          aria-expanded="false"
                          style={{ visibility: "hidden", order: "1" }}
                        >
                          <span
                            role="img"
                            aria-label="ellipsis"
                            className="anticon anticon-ellipsis"
                          >
                            <svg
                              viewBox="64 64 896 896"
                              focusable="false"
                              data-icon="ellipsis"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z"></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>

                    {betStatus == "story" && (
                      <div className="ant-tabs-content-holder">
                        <div className="ant-tabs-content ant-tabs-content-top">
                          <div
                            role="tabpanel"
                            tabindex="0"
                            aria-hidden="false"
                            className="ant-tabs-tabpane ant-tabs-tabpane-active customTabs"
                            id="rc-tabs-3-panel-1"
                            aria-labelledby="rc-tabs-3-tab-1"
                          >
                            <p className="pt-4">
                              The genesis of the Pikaverse came by way of the
                              Pikamoon Beta, which launched in 2023. Such event
                              kick-started a movement that saw a small community
                              of GameFi enthusiasts grow into a thriving strong
                              player base.
                            </p>
                            <p className="pt-4">
                              Based on multiplayer online battle arena (MOBA)
                              dynamics, the game tasks players with assembling
                              the strongest team of Pikamoon NFTs as possible.
                            </p>
                            <p className="pt-4">
                              Pikamoon characters are then pitted against each
                              other within an immersive Battle Arena, with the
                              game’s native $PIKA token being the reward on
                              offer.
                            </p>
                            <p className="pt-4">
                              With deep lore, masterful gameplay, and real-world
                              rewards on the line, Pikamoon plays hosts to one
                              of the most promising blockchain gaming ecosystems
                              on the market.
                            </p>
                            <p className="pt-4">
                              Play the Beta now to get a head start in the game.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    {betStatus == "factions" && (
                      <div
                        id="rc-tabs-2-panel-2"
                        role="tabpanel"
                        tabindex="0"
                        aria-labelledby="rc-tabs-2-tab-2"
                        aria-hidden="false"
                        className="ant-tabs-tabpane ant-tabs-tabpane-active"
                      >
                        <div className="grid grid-cols-6 gap-4">
                          <img
                            alt="/images/red_faction.svg"
                            fetchpriority="high"
                            width="85"
                            height="74"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="/images/red_faction.svg"
                            style={{ color: "transparent" }}
                          />
                          <img
                            alt="/images/orange_faction.svg"
                            fetchpriority="high"
                            width="85"
                            height="74"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="/images/orange_faction.svg"
                            style={{ color: "transparent" }}
                          />
                          <img
                            alt="/images/blue_faction.svg"
                            fetchpriority="high"
                            width="85"
                            height="74"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="/images/blue_faction.svg"
                            style={{ color: "transparent" }}
                          />
                          <img
                            alt="/images/brown_faction.svg"
                            fetchpriority="high"
                            width="85"
                            height="74"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="/images/brown_faction.svg"
                            style={{ color: "transparent" }}
                          />
                          <img
                            alt="/images/dark_blue_faction.svg"
                            fetchpriority="high"
                            width="85"
                            height="74"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="/images/dark_blue_faction.svg"
                            style={{ color: "transparent" }}
                          />
                          <img
                            alt="/images/green_faction.svg"
                            fetchpriority="high"
                            width="85"
                            height="74"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="/images/green_faction.svg"
                            style={{ color: "transparent" }}
                          />
                        </div>
                      </div>
                    )}

                    {betStatus == "collectibles" && (
                      <div
                        id="rc-tabs-0-panel-3"
                        role="tabpanel"
                        tabindex="0"
                        aria-labelledby="rc-tabs-0-tab-3"
                        aria-hidden="false"
                        className="ant-tabs-tabpane ant-tabs-tabpane-active"
                      >
                        <div className="flex">
                          <img
                            alt="/images/ic_bottle_left.svg"
                            fetchpriority="high"
                            width="85"
                            height="74"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="/images/ic_bottle_left.svg"
                            style={{ color: "transparent" }}
                          />
                          <img
                            alt="/images/ic_bottle_thunder.svg"
                            fetchpriority="high"
                            width="85"
                            height="74"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="/images/ic_bottle_thunder.svg"
                            style={{ color: "transparent" }}
                          />
                          <img
                            alt="/images/ic_bottle_health.svg"
                            fetchpriority="high"
                            width="85"
                            height="74"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="/images/ic_bottle_health.svg"
                            style={{ color: "transparent" }}
                          />
                          <img
                            alt="/images/ic_bottle_water.svg"
                            fetchpriority="high"
                            width="85"
                            height="74"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="/images/ic_bottle_water.svg"
                            style={{ color: "transparent" }}
                          />
                          <img
                            alt="/images/ic_bottle_bomb.svg"
                            fetchpriority="high"
                            width="85"
                            height="74"
                            decoding="async"
                            data-nimg="1"
                            className="w-full"
                            src="/images/ic_bottle_bomb.svg"
                            style={{ color: "transparent" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <h3 className="orangeTextClash  pt-8 pb-6 ">
                    System Requirements
                  </h3>
                  <p className="customTabs mb-6">
                    All iOS &amp; Android devices are supported. This includes
                    very old models, however these may run on lower frames.
                  </p>
                  <div
                    id="gameTab"
                    className="ant-tabs ant-tabs-top ant-tabs-card css-1b0bdye"
                  >
                    <div role="tablist" className="ant-tabs-nav">
                      <div className="ant-tabs-nav-wrap">
                        <div
                          className="ant-tabs-nav-list flex"
                          style={{ transform: "translate(0px, 0px)" }}
                        >
                          <div
                            data-node-key="1"
                            className="ant-tabs-tab ant-tabs-tab-active mr-[5px]"
                          >
                            <div
                              role="tab"
                              aria-selected="true"
                              id="gameTab-tab-1"
                              className={classnames(
                                {
                                  "text-[#F24822] font-[700] border border-black":
                                    system == "android",
                                },
                                "cursor-pointer  bg-white  font-[700] border  text-[24px] md:text-[28px] w-full p-[10px_20px] rounded-[10px] whitespace-nowrap"
                              )}
                              onClick={() => {
                                setSystem("android");
                              }}
                              aria-controls="gameTab-panel-1"
                              tabindex="0"
                            >
                              Android
                            </div>
                          </div>
                          <div
                            data-node-key="2"
                            className="ant-tabs-tab mr-[5px]"
                          >
                            <div
                              role="tab"
                              aria-selected="false"
                              id="gameTab-tab-2"
                              className={classnames(
                                {
                                  "text-[#F24822] font-[700] border border-black":
                                    system == "apple",
                                },
                                "cursor-pointer  bg-white  font-[700] border  text-[24px] md:text-[28px] w-full p-[10px_20px] rounded-[10px] whitespace-nowrap"
                              )}
                              onClick={() => {
                                setSystem("apple");
                              }}
                              aria-controls="gameTab-panel-2"
                              tabindex="0"
                            >
                              Apple
                            </div>
                          </div>
                          <div
                            className="ant-tabs-ink-bar ant-tabs-ink-bar-animated"
                            style={{
                              width: "0px",
                              left: "0px",
                              transform: "translateX(-50%)",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="ant-tabs-nav-operations ant-tabs-nav-operations-hidden">
                        <button
                          type="button"
                          className="ant-tabs-nav-more"
                          tabindex="-1"
                          aria-hidden="true"
                          aria-haspopup="listbox"
                          aria-controls="gameTab-more-popup"
                          id="gameTab-more"
                          aria-expanded="false"
                          style={{ visibility: "hidden", order: "1" }}
                        >
                          <span
                            role="img"
                            aria-label="ellipsis"
                            className="anticon anticon-ellipsis"
                          >
                            <svg
                              viewBox="64 64 896 896"
                              focusable="false"
                              data-icon="ellipsis"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z"></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="ant-tabs-content-holder">
                      <div className="ant-tabs-content ant-tabs-content-top">
                        {system == "android" && (
                          <div
                            id="gameTab-panel-1"
                            role="tabpanel"
                            tabindex="0"
                            aria-labelledby="gameTab-tab-1"
                            aria-hidden="false"
                            className="ant-tabs-tabpane ant-tabs-tabpane-active customTabs"
                          >
                            <p>
                              Version 5.1 Lollipop or later is recommended for
                              the best experience.
                            </p>
                          </div>
                        )}

                        {system == "apple" && (
                          <div
                            id="gameTab-panel-2"
                            role="tabpanel"
                            tabindex="0"
                            aria-labelledby="gameTab-tab-2"
                            aria-hidden="false"
                            className="ant-tabs-tabpane ant-tabs-tabpane-active customTabs"
                          >
                            <p>
                              iOS 12.3 or later is recommended for the best
                              experience.
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default PlayBeta;
