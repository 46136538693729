import React, { useState, useEffect } from "react";
import classnames from "classnames";

import Footer from "../layout/Footer";
import Blog from "../layout/Blog";
import { ethers, BigNumber } from "ethers";
import {
  useAccount,
  useReadContract,
  useBalance,
  useWriteContract,
  useWaitForTransactionReceipt,
} from "wagmi";

import PresaleABI from "../abis/CF_ico.json";
import { ToastContainer, toast } from "react-toastify";
import { Warning } from "@mui/icons-material";

// import { Toast } from "react-toastify/dist/components";

const presaleContract = "0x342455541f2489a26c1be2c7d28a821712ea521e";

const Token = () => {
  // const [nftfilter, setNFtfilter] = useState("fire");

  const { address, isConnecting, isDisconnected } = useAccount();

  const [tokenAmount, setTokenAmount] = useState("0");

  const [activePhase, setActivePhase] = useState(0);

  const [raisedAmount, setRaisedAmount] = useState(0);

  const [tokenPrice, setTokenPrice] = useState(0);

  const [totalSold, setTotalSold] = useState(0);

  const [maxTokens, setMaxTokens] = useState(0);

  const [phase, setPhase] = useState([]);

  const [bnbAmount, setBnbAmount] = useState("0");

  const [bnbBalance, setBnbBalance] = useState("0");

  const [claimablePhase1, setClaimablePhase1] = useState("0");
  const [claimablePhase2, setClaimablePhase2] = useState("0");
  const [claimablePhase3, setClaimablePhase3] = useState("0");

  const [totalClaimable, setTotalClaimable] = useState("0");

  const [claimableActive, setClaimableActive] = useState("1");

  // const [finalBNB, setFinalBNB] = useState(0);

  const activePhaseTmp = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "activePhase",
    // chainId:97
  });

  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    isRejected: isRejected,
  } = useWaitForTransactionReceipt({
    hash,
  });
  useEffect(() => {
    if (error) {
      console.log("contract write error", error);
      const startIndex = error.message.indexOf("following reason:");
      const endIndex = error.message.indexOf("Contract Call:");

      // console.log("startIndex", startIndex);
      // console.log("endIndex", endIndex);
      const modifiedSentence = error.message.slice(startIndex + 17, endIndex);
      // console.log("modifiedSentence", modifiedSentence)
      toast(modifiedSentence);
    }
  }, [error]);

  // console.log("hash", hash);
  useEffect(() => {
    if (activePhaseTmp.data) {
      setActivePhase(activePhaseTmp.data.toString());
    }
  }, [activePhaseTmp]);

  const phaseTmp = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "phase",
    args: [activePhase],
    // chainId:97
  });

  const tokenAmountTmp = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "estimatedToken",
    args: [bnbAmount],
    // chainId:97
  });

  const bnbBalanceTmp = useBalance({
    address: address,
  });

  useEffect(() => {
    if (bnbBalanceTmp.data?.value) {
      // const weiAmount = ethers.utils.parseUnits(bnbBalanceTmp.data.value.toString(), 'ether');
      // setBnbBalance(weiAmount);

      setBnbBalance(bnbBalanceTmp.data.value.toString());
      // console.log(bnbBalanceTmp.data.value.toString());
    }
  }, [bnbBalanceTmp]);

  useEffect(() => {
    if (isConfirmed) {
      toast("Transaction is Succeed!");
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (isRejected) {
      toast("You rejected to sign transaction!");
    }
  }, [isRejected]);

  useEffect(() => {
    if (tokenAmountTmp.data) {
      // console.log(phaseTmp.data);
      // const tokenAmountWei = BigNumber.from(tokenAmountTmp.data.toString());
      // const tokenAmountbnb = ethers.utils.formatUnits(tokenAmountWei, 18);
      setTokenAmount(tokenAmountTmp.data.toString());
    }
  }, [tokenAmountTmp]);

  useEffect(() => {
    if (phaseTmp.data) {
      // console.log(phaseTmp.data);
      setPhase(phaseTmp.data);
      // setTokenPrice(phase[4].toString())
      // console.log(phaseTmp.data[4].toString());
    }
  }, [phaseTmp]);

  useEffect(() => {
    if (phase.length > 0) {
      setTokenPrice((phase[4].toString() * 1) / 10 ** 18);
      setRaisedAmount((phase[3].toString() * 1) / 10 ** 18);
      // console.log("totalsold",phase[2].toString() * 1 )
      // setMaxTokens((phase[1].toString() * 1) / 10 ** 18);
      setMaxTokens(phase[1].toString() * 1);
      setTotalSold((phase[2].toString() * 1) / 10 ** 18);

      // console.log((phase[1].toString() * 1) / (10 ** 18));
      // console.log(phase[2].toString() * 1);
    }
  }, [phase]);

  const deserveTmpPhase1 = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "deservedAmount",
    args: [address, 1],
  });

  const claimedAmountPhase1 = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "claimedAmount",
    args: [address, 1],
  });

  useEffect(() => {
    //  console.log("wat is",deserveTmpPhase1.data.toString() -  claimedAmountPhase1.data.toString());
    if (deserveTmpPhase1 && claimedAmountPhase1) {
      try {
        setClaimablePhase1(
          deserveTmpPhase1.data.toString() - claimedAmountPhase1.data.toString()
        );
      } catch (err) {}
    }
  }, [deserveTmpPhase1, claimedAmountPhase1]);

  const deserveTmpPhase2 = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "deservedAmount",
    args: [address, 2],
  });

  const claimedAmountPhase2 = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "claimedAmount",
    args: [address, 2],
  });

  useEffect(() => {
    if (deserveTmpPhase2 && claimedAmountPhase2) {
      try {
        setClaimablePhase2(
          deserveTmpPhase2.data.toString() - claimedAmountPhase2.data.toString()
        );
      } catch (err) {}
    }
  }, [deserveTmpPhase2, claimedAmountPhase2]);

  const deserveTmpPhase3 = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "deservedAmount",
    args: [address, 3],
  });

  const claimedAmountPhase3 = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "claimedAmount",
    args: [address, 3],
  });

  useEffect(() => {
    if (deserveTmpPhase3 && claimedAmountPhase3) {
      try {
        setClaimablePhase3(
          deserveTmpPhase3.data.toString() - claimedAmountPhase3.data.toString()
        );
      } catch (err) {}
    }
  }, [deserveTmpPhase3, claimedAmountPhase3]);

  useEffect(() => {
    setTotalClaimable(claimablePhase1 + claimablePhase2 + claimablePhase3);
  }, [claimablePhase1, claimablePhase2, claimablePhase3]);

  return (
    <>
      <div>
        <div className="relative fon">
          <div className="max-w-[1226px] w-full mx-auto relative">
            <div className="absolute top-0 right-[0] flex justify-end z-[2] overflow-hidden">
              <a target="_blank" href="https://twitter.com/CryptoFuncoin">
                <img
                  alt="x"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/X.svg"
                />
              </a>
              <a target="_blank" href="https://t.me/CryptoFunofficial">
                <img
                  alt="Telegram"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/Telegram.svg"
                />
              </a>
              <a target="_blank" href="https://discord.com/invite/QFHbbKQed9">
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/Discord.svg"
                />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/CryptoFuncoin_/"
              >
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/Instagram.svg"
                />
              </a>
              <a target="_blank" href="https://www.youtube.com/@CryptoFuncoin">
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/youtubeicon.svg"
                />
              </a>
              <a
                target="_blank"
                href="https://www.reddit.com/r/officialCryptoFun/"
              >
                <img
                  alt="Discord"
                  fetchpriority="high"
                  width="50"
                  height="50"
                  decoding="async"
                  data-nimg="1"
                  className="hover:scale-[1.2] transtionHover cursor-pointer w-[42px] md:w-[50px]"
                  style={{ color: "transparent" }}
                  src="/images/reddit.svg"
                />
              </a>
            </div>
          </div>
          <div className="marquee text-[14px] text-white bg-black relative h-[42px] md:h-[50px] flex items-center overflow-hidden">
            <section>
              <div className="flex items-center flex-nowrap min-w-fit font-[700]">
                <a className=" min-w-fit fontEnigma" href="/buy">
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="../images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
                <a className=" min-w-fit fontEnigma" href="/buy">
                  {" "}
                  $CryptoFun is now trading on
                </a>
                <a className="co-yellow mx-2 min-w-fit fontEnigma" href="/buy">
                  BITGET, MEXC &amp; UNISWAP
                </a>
                <img
                  alt="close"
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  data-nimg="1"
                  className="mx-2"
                  style={{ color: "transparent" }}
                  src="/images/star.svg"
                />
              </div>
            </section>
          </div>
        </div>

        <div className="z-[9] co-navbar  px-[20px] h-[80px] border-b border-b-[2.5px] border-black text-black bg-gradient-to-r from-[#ffbc00] to-[#ff9100] hidden md:block sticky top-[-2px] bg-[#ffffff a-z-30">
          <div className="max-w-[1223px] w-full h-full mx-auto block md:flex items-center gap-x-2 flex-wrap md:flex-nowrap lg:gap-x-10">
            <div className="flex md:block items-center justify-between">
              <a href="/">
                <img
                  alt="close"
                  loading="lazy"
                  width="80"
                  height="80"
                  decoding="async"
                  data-nimg="1"
                  className="relative top-[15px] transition-transform ease-in-out z-[1] hover:scale-[1.2] transform origin-center will-change-transform"
                  style={{ color: "transparent" }}
                  src="/images/logo.svg"
                />
              </a>

              <a
                className=" custom-bg-hover hover:text-white relative flex md:hidden justify-center items-center w-[148px] h-[35px]"
                href="/token"
              >
                {/* <svg
                  className="absolute left-0 right-0 top-0"
                  width="148"
                  height="35"
                  viewBox="0 0 148 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M148 15.4447C148 18.0797 146.96 20.6082 145.106 22.4806L135.645 32.036C133.767 33.9328 131.208 35 128.539 35L10 35C4.47716 35 -3.91405e-07 30.5228 -8.74228e-07 25L-2.18557e-06 10C-2.66839e-06 4.47715 4.47715 -3.91405e-07 10 -8.74228e-07L138 -1.20643e-05C143.523 -1.25472e-05 148 4.47714 148 9.99999L148 15.4447Z"
                    fill="white"
                  ></path>
                </svg>
                <span className=" text-[14px] relative z-[2] flex items-center gap-[10px]">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13L13 1.00001M13 1.00001L2.2 1.00001M13 1.00001L13 11.8"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  BUY $CryptoFun
                </span> */}
                 <img
                  alt="buyPikaArrow"
                  fetchpriority="high"
                  width="148"
                  height="35"
                  decoding="async"
                  data-nimg="1"
                  class=""
                  style={{ color: "transparent" }}
                  src="/images/buyPikaArrow.svg"
                />
              </a>
            </div>

            <ul className="flex gap-[10px] lg:gap-[25px] items-center justify-center  md:justify-start bg-gradient-to-r from-[#ffbc00] to-[#ff9100] p-[15px] md:p-0 md:bg-none  ">
              <li>
                <a
                  className=" menu-link-effect text-[18px] lg:text-[20px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                  href="/play-beta"
                >
                  Play BETA{" "}
                  <span className="absolute right-[-14px] top-0 opacity-0">
                    <img
                      alt="Menu"
                      loading="lazy"
                      width="26"
                      height="40"
                      decoding="async"
                      data-nimg="1"
                      src="../images/menu-shape.png"
                      style={{ color: "transparent" }}
                    />
                  </span>{" "}
                </a>
              </li>

              <li>
                <a
                  className="false  menu-link-effect text-[18px] lg:text-[20px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                  href="/our-journey"
                >
                  Our Journey{" "}
                  <span className="absolute right-[-14px] top-0 opacity-0">
                    <img
                      alt="Menu"
                      loading="lazy"
                      width="26"
                      height="40"
                      decoding="async"
                      data-nimg="1"
                      src="../images/menu-shape.png"
                      style={{ color: "transparent" }}
                    />
                  </span>{" "}
                </a>
              </li>
            </ul>

            <div className="ml-auto flex items-center gap-x-1 lg:gap-x-2 2xl:gap-x-5" />
            <div>
            <a className="hidden md:block padding-[10px] pl-[20px]  text-center md:p-0">
                <w3m-button>Connect Wallet</w3m-button>
              </a>
            </div>

            <p className="hidden md:block bg-transparent outline-0 font-semibold text-[18px] capitalize tracking-[-0.36px]">
              ENG
            </p>
          </div>
        </div>

        <input type="checkbox" class="hidden menu-active" id="Mobile-menu"></input>
        <div class="mobile-header co-navbar px-[20px] h-auto text-black bg-gradient-to-r from-[#ffbc00] to-[#ff9100] block md:hidden relative z-[99] sticky top-[-2px]" >
          <div class="flex items-center gap-x-2 flex-wrap md:flex-nowrap lg:gap-x-10">
            <a href="/">
              <img
                alt="close"
                fetchpriority="high"
                width="80"
                height="80"
                decoding="async"
                data-nimg="1"
                class="logo-postion hover:scale-[1.2] transtionHoverLogo z-[1]"
                style={{ color: "transparent" }}
                src="/images/logo.svg"
              />
            </a>
            <div class="ml-auto flex items-center gap-x-[22px] lg:gap-x-2 2xl:gap-x-5">
            <a className="block md:hidden padding-[10px] pl-[20px]  text-center md:p-0">
                <w3m-button>Connect Wallet</w3m-button>
              </a>
              <label for="Mobile-menu">
                <img
                  alt="Mobile Menu"
                  loading="lazy"
                  width="29"
                  height="29"
                  decoding="async"
                  data-nimg="1"
                  style={{ color: "transparent" }}
                  src="/images/Mobile-menu.5ef721ad.svg"
                  class="menu-mobile1"
                />
                <img
                  alt="Mobile Menu"
                  loading="lazy"
                  width="29"
                  height="29"
                  decoding="async"
                  data-nimg="1"
                  class="menu-mobile2"
                  style={{ color: "transparent" }}
                  src="/images/menu-close.2bb71f01.svg"
                />
              </label>
            </div>
          </div>
          <ul class="mobile-menu-list ease-out flex flex-col gap-[5px] w-full max-h-[0] overflow-hidden opacity-0" >
         
            <li>
              <a
                class=" h-[40px] md:h-auto inline-flex menu-link-effect text-[18px] lg:text-[18px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                href="/play-beta"
              >
                Play Beta
                <span class="absolute right-[-14px] top-0 opacity-0">
                  <img
                    alt="Menu"
                    loading="lazy"
                    width="26"
                    height="40"
                    decoding="async"
                    data-nimg="1"
                    style={{ color: "transparent" }}
                    
                    src="/images/menu-shape.2f1d76fd.png&amp;w=64&amp;q=75"
                  />
                </span>
              </a>
            </li>
         
            <li>
              <a
                class=" h-[40px] md:h-auto menu-link-effect inline-flex text-[18px] lg:text-[18px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                href="/our-journey"
              >
                Our Journey{" "}
                <span class="absolute right-[-14px] top-0 opacity-0">
                  <img
                    alt="Menu"
                    loading="lazy"
                    width="26"
                    height="40"
                    decoding="async"
                    data-nimg="1"
                    style={{ color: "transparent" }}
                   
                    src="images/menu-shape.2f1d76fd.png"
                  />
                </span>
              </a>
            </li>
          </ul>
        </div>

        <div className="pt-[60px] pb-[50px] bg-[url('/images/bg-gradient.svg')] bg-cover bg-center-top relative min-h-[1000px]">
          <div className="absolute top-[60px] bottom-0 left-0 right-0 !pointer-events-none">
            <div className="flex justify-between items-center lg:sticky left-0 right-0 top-[0]">
              <img
                alt="Cloud"
                loading="lazy"
                width="274"
                height="152"
                decoding="async"
                data-nimg="1"
                className="cloud-1 max-w-[40%]"
                src="../images/cloud-1.429b1c46.svg"
                style={{ color: "transparent" }}
              />
              <img
                alt="Cloud"
                loading="lazy"
                width="319"
                height="160"
                decoding="async"
                data-nimg="1"
                className="cloud-2 max-w-[40%]"
                src="../images/cloud-2.73cacac8.svg"
                style={{ color: "transparent" }}
              />
            </div>
            <div className="flex justify-between items-center lg:sticky left-0 right-0 top-[450px] mt-[450px]">
              <img
                alt="Cloud"
                loading="lazy"
                width="327"
                height="156"
                decoding="async"
                data-nimg="1"
                className="cloud-3 max-w-[40%]"
                src="../images/cloud-3.05115ebe.svg"
                style={{ color: "transparent" }}
              />
              <img
                alt="Cloud"
                loading="lazy"
                width="299"
                height="253"
                decoding="async"
                data-nimg="1"
                className="cloud-4 max-w-[40%]"
                src="../images/cloud-4.5f81391a.svg"
                style={{ color: "transparent" }}
              />
            </div>
          </div>
          <section className="max-w-[1256px] w-full mx-auto px-[15px] sm:px-[30px] pb-[60px] relative z-[2]">
            <div className="flex flex-col lg:flex-row justify-between md:gap-[50px]">
              <div className="w-full md:w-1/2 flex flex-col gap-[20px]">
                <h5 className="orangeTextClash ">
                  Adding $CryptoFun Tokens to Your Wallet
                </h5>
                <p className="text-[20px] font-normal leading-[30px] tracking-[-0.4px]">
                  After buying your $CryptoFun tokens, they may not
                  automatically appear in your wallet.
                </p>
                <p className="text-[20px] font-normal leading-[30px] tracking-[-0.4px]">
                  To view your tokens, add them manually using the token
                  contract address (to be provided upon deployment and audit on
                  the main net).
                </p>
                <p className="text-[20px] font-normal leading-[30px] tracking-[-0.4px]">
                  Here's a quick guide:
                </p>
                <ul className="game-fi">
                  <li>1. Access your web3 wallet (e.g., Metamask).</li>
                  <li>2. Navigate to the "Add Token" section.</li>
                  <li>
                    3. Enter the $CryptoFun token contract: <br />
                    <span className="break-words font-bold">
                      0x5751c08c23cad8f569d045f72654d388fe6d1e20
                    </span>
                  </li>
                  <li>
                    4. Confirm the addition to see your $CryptoFun tokens in
                    your wallet.
                  </li>
                </ul>
              </div>
              <div className="lg:max-w-[600px] w-full">
                {!isDisconnected && (
                  <section>
                    <div className="cardBuyPika  pt-[5px] pb-[5px] pl-[32px] pr-[32px]">
                      <p className="blackTextClash text-[24px] md:text-[32px] text-center py-5">
                        Buy Now Your $CryptoFun
                      </p>
                      <p>(Phase {activePhase})</p>
                      <div>
                        <div className="flex justify-between">
                          <span className="text-sm font-medium text-blue-700 dark:text-white"></span>
                        </div>
                        <div className="w-[100%] rounded-full h-[20px] relative">
                          <div
                            className="backgroundOrangeLinear h-[30px] rounded-full"
                            style={{ width: "100%" }}
                          >
                            <p className="absolute right-[15px] text-[20px] font-bold co-white uppercase">
                              {(100 - (totalSold / maxTokens) * 100).toFixed(5)}
                              %
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col py-5 space-y-4">
                          <div className="flex justify-between items-center gap-[15px]">
                            <p className="co-gray text-[18px] md:text-[22px]">
                              Total Supply:
                            </p>
                            <div className="text-right flex flex-wrap justify-end items-center text-[14px] md:text-[18px]">
                              <span className="uppercase font-bold">
                                15000000000
                              </span>
                              <span className="uppercase font-bold"> CF</span>
                              <svg
                                className="ml-2 w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  d="M10 7H10.01M9 10H10V14H11M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 7.61305 18.0518 5.32387 16.364 3.63604C14.6761 1.94821 12.3869 1 10 1C7.61305 1 5.32387 1.94821 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10Z"
                                  stroke="#9EB0CB"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div className="flex gap-[15px] justify-between items-center h-10">
                            <p className="co-gray text-[18px] md:text-[22px]">
                              Supply For Presale:
                            </p>
                            <div className="flex items-center ">
                              <span className="uppercase font-bold text-[14px] md:text-[18px]">
                                {maxTokens}
                              </span>
                              <span className="uppercase font-bold"> CF</span>
                              <svg
                                className="ml-2 w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  d="M10 7H10.01M9 10H10V14H11M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 7.61305 18.0518 5.32387 16.364 3.63604C14.6761 1.94821 12.3869 1 10 1C7.61305 1 5.32387 1.94821 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10Z"
                                  stroke="#9EB0CB"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div className="flex gap-[15px] justify-between items-center h-10">
                            <p className="co-gray text-[18px] md:text-[22px]">
                              Total Raised:
                            </p>
                            <div className="flex items-center ">
                              <span className="uppercase font-bold text-[14px] md:text-[18px]">
                                {raisedAmount} BNB
                              </span>
                              <svg
                                className="ml-2 w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  d="M10 7H10.01M9 10H10V14H11M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 7.61305 18.0518 5.32387 16.364 3.63604C14.6761 1.94821 12.3869 1 10 1C7.61305 1 5.32387 1.94821 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10Z"
                                  stroke="#9EB0CB"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div className="claim-buttons">
                            <div className="btn  p-2 w-full h-[60px] my-3 z-[9999] btn-orange">
                              1 CryptoFun = {tokenPrice}USD
                            </div>
                            {/* <button
                            className="btnConnectWallet w-full h-[60px] my-3 z-[9999]"
                            id="connectWalletButton"
                          >
                            
                          </button> */}
                            {address && (
                              <>
                                <div className="flex items-center border-2 border-solid border-#C0CCDD rounded h-[60px]">
                                  <input
                                    className="flex-grow focus:outline-none p-[10px] md:p-2 rounded"
                                    type="number"
                                    style={{
                                      color: "black",
                                      maxWidth: "768px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      textAlign: "center",
                                    }}
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        const weiAmount =
                                          ethers.utils.parseUnits(
                                            e.target.value.toString(),
                                            "ether"
                                          );
                                        setBnbAmount(weiAmount);
                                      }
                                    }}
                                  />
                                  <button className="bg-custom-color text-white px-1 py-4 md:px-4 md:py-4 rounded-tr-md rounded-br-md rounded-bl-none rounded-tl-none flex-shrink-0 flex-basis-auto w-32">
                                    BNB
                                  </button>
                                </div>
                                <br />
                                <div className="flex items-center border-2 border-solid border-#C0CCDD rounded h-[60px]">
                                  <input
                                    className="flex-grow focus:outline-none p-[10px] md:p-2 rounded"
                                    type="number"
                                    value={
                                      isDisconnected
                                        ? setTokenAmount(0)
                                        : tokenAmount
                                    }
                                    readOnly="true"
                                    style={{
                                      color: "black",
                                      maxWidth: "768px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      textAlign: "center",
                                    }}
                                  />
                                  <button className="bg-custom-color text-white px-1 py-4 md:px-4 md:py-4 rounded-tr-md rounded-br-md rounded-bl-none rounded-tl-none flex-shrink-0 flex-basis-auto w-32">
                                    $ CF
                                  </button>
                                </div>
                                <button
                                  className="btnConnectWallet w-full h-[60px] my-3 z-[9999] text-white btn-green"
                                  id="buyButton"
                                  disabled={isPending}
                                  onClick={async () => {
                                    if (bnbAmount == 0) {
                                      toast("Input BNB amount to buy tokens");
                                    }
                                    if (
                                      BigNumber.from(bnbBalance).lt(
                                        BigNumber.from(bnbAmount)
                                      )
                                    ) {
                                      toast("Your Balance is not enough!");
                                    } else {
                                      console.log("bnbAmount", bnbAmount);
                                      const res = writeContract({
                                        abi: PresaleABI,
                                        address: presaleContract,
                                        functionName: "buyTokensEth",
                                        value: bnbAmount,
                                      });
                                    }
                                  }}
                                >
                                  {isPending || isRejected
                                    ? "Confirming..."
                                    : "Buy Now"}
                                </button>
                                <div className="flex gap-[15px] justify-between items-center h-10">
                                  <p className="co-gray text-[18px] md:text-[22px]">
                                    Total Claimable:
                                  </p>
                                  <div className="flex items-center ">
                                    <span className="uppercase font-bold text-[14px] md:text-[18px]">
                                      {totalClaimable} CF
                                    </span>
                                    <svg
                                      className="ml-2 w-5 h-5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        d="M10 7H10.01M9 10H10V14H11M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 7.61305 18.0518 5.32387 16.364 3.63604C14.6761 1.94821 12.3869 1 10 1C7.61305 1 5.32387 1.94821 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10Z"
                                        stroke="#9EB0CB"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                                <div className="flex items-center border-2 border-solid border-#C0CCDD rounded h-[60px]">
                                  <select
                                    className="flex-grow focus:outline-none p-[10px] md:p-2 rounded"
                                    type="number"
                                    style={{
                                      color: "black",
                                      maxWidth: "768px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      textAlign: "center",
                                    }}
                                    onChange={(e) => {
                                      console.log(
                                        "claimableActive",
                                        e.target.value
                                      );
                                      setClaimableActive(e.target.value);
                                    }}
                                  >
                                    <option value={1}>
                                      Phase 1 ({claimablePhase1} CF Claimable)
                                    </option>
                                    <option value={2}>
                                      Phase 2 ({claimablePhase2} CF Claimable)
                                    </option>
                                    <option value={3}>
                                      Phase 3 ({claimablePhase3} CF Claimable)
                                    </option>
                                  </select>
                                </div>

                                <div className="buttons-claiming flex justify-between">
                                  <button
                                    className="btn btn-transparent w-full h-[60px] my-3 z-[9999] btn-green"
                                    style={{ width: "100%" }}
                                    onClick={async () => {
                                      console.log(
                                        "claimableActive",
                                        claimableActive
                                      );
                                      const res = writeContract({
                                        abi: PresaleABI,
                                        address: presaleContract,
                                        functionName: "claim",
                                        args: [claimableActive],
                                      });
                                    }}
                                  >
                                    {isPending || isRejected
                                      ? "Confirming..."
                                      : "Claim CryptoFun"}
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                          {/* <p className="text-lg font-medium text-center copy-text">
                          Copy this Address to ADD $CryptoFun to your Wallet
                        </p>
                        <div className="flex items-center border-2 border-solid border-#C0CCDD rounded h-[60px]">
                          <input
                            readonly=""
                            className="flex-grow focus:outline-none p-[10px] md:p-2 rounded"
                            type="text"
                            value="0xd21384a3fd14ad73f97e879b7e41d9a658e30f00"
                            style={{
                              color: "rgb(192, 204, 221)",
                              maxWidth: "768px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              textAlign: "center",
                            }}
                          />
                          <button className="bg-custom-color text-white px-1 py-4 md:px-4 md:py-4 rounded-tr-md rounded-br-md rounded-bl-none rounded-tl-none flex-shrink-0 flex-basis-auto">
                            <img
                              src="../images/copy-link.svg"
                              alt="Copy Link"
                              className="w-6 h-[25px]"
                            />
                          </button>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </section>
                )}
                {isDisconnected && (
                  <div className="cardBuyPika pt-[5px] pb-[5px] pl-[32px] pr-[32px] text-center">
                    <p className="blackTextClash text-[24px] md:text-[32px] text-center py-5">
                      You need to connect Web3 wallet to buy CryptoFun Token
                    </p>
                    <a className="padding-[10px] pl-[20px]  text-center md:p-0 m-auto flex justify-center items-center">
                      <w3m-button className="m-auto">Connect Wallet</w3m-button>
                    </a>
                    <br />
                  </div>
                )}
              </div>
              <div className="lg:max-w-[600px] w-full block md:hidden mt-[25px]">
                <p className="font-medium text-black tracking-[-0.56px] text-[28px] pb-4 block">
                  Start Earning Rewards - $CryptoFun Staking Will Become Live
                  After Launch
                </p>
                <p className="text-xl font-normal pb-8 leading-[32px] block">
                  <span className="text-red-150 ">*</span>Rewards are calculated
                  based on your share of the $CryptoFun staking pool &amp; the
                  annual returns percentage.
                </p>
                <a
                  id="bgWhiteCoBlackBorderBlack"
                  className="inline-block "
                  href="#"
                >
                  <div className="relative">
                    <svg
                      width="240"
                      height="60"
                      fill="#F24822"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer transition duration-1000 ease-out text-[25px]"
                    >
                      <path
                        d="M229.808 35.6904C231.222 34.0552 232 31.9658 232 29.8042L232 10C232 5.02944 227.971 1 223 1L9.99999 1.00002C5.02943 1.00002 0.999995 5.02946 0.999996 10L0.999999 50C1 54.9706 5.02943 59 9.99999 59L205.539 59C208.153 59 210.638 57.8636 212.347 55.8862L229.808 35.6904Z"
                        stroke="none"
                        strokeWidth="2"
                      ></path>
                      <text x="50%" y="40" fill="white" text-anchor="middle">
                        Buy Now
                      </text>
                    </svg>
                    <img
                      alt="Header"
                      fetchpriority="high"
                      width="240"
                      height="17"
                      decoding="async"
                      data-nimg="1"
                      className="absolute bottom-[-26px] z-[-1] right-4"
                      src="../images/shadowImage.png"
                      style={{ color: "transparent" }}
                    />
                  </div>
                </a>
                <div className="flex gap-x-3 pt-[36px]">
                  <img
                    alt="arrow"
                    fetchpriority="high"
                    width="15"
                    height="15"
                    decoding="async"
                    data-nimg="1"
                    className=""
                    src="/images/arrow.svg"
                    style={{ color: "transparent" }}
                  />
                  <p className="capitalize font-semibold fontClash text-[22px]">
                    More updates coming soon
                  </p>
                </div>
              </div>
            </div>
            <section className=" max-w-[1226px] w-full mx-auto relative z-[2]">
              <h5
                className="orangeTextClash mt-[70px] md:mt-[150px] mb-[25px] px-[30px]"
                id="buy"
              >
                Claiming Your $CryptoFun Tokens
              </h5>
              <div className="flex flex-col md:flex-row gap-[30px] md: px-[30px]  ease-in ">
                <div className="w-full md:w-1/3 flex flex-col">
                  <h1 className="blackTextClash mb-[10px]">Step 1:</h1>
                  <ul className="steps-list">
                    <li>
                      Connect your web3 compatible wallet that you used to
                      participate in the CryptoFun Presale.
                    </li>
                    <li>
                      Navigate to the "Claim Tokens" section on our platform.
                    </li>
                    <li>Select "Connect Wallet" to link your wallet.</li>
                  </ul>
                </div>
                <div className="w-full md:w-1/3 flex flex-col">
                  <h1 className="blackTextClash mb-[10px]">Step 2:</h1>
                  <ul className="steps-list">
                    <li>
                      Follow the on-screen instructions to verify your presale
                      participation.
                    </li>
                    <li>
                      Once verified, you will see the amount of $CryptoFun
                      you're eligible to claim.
                    </li>
                  </ul>
                </div>
                <div className="w-full md:w-1/3 flex flex-col">
                  <h1 className="blackTextClash mb-[10px]">Step 3:</h1>
                  <ul className="steps-list">
                    <li>Click "CLAIM $CryptoFun".</li>
                    <li>
                      Your wallet will prompt you to confirm the transaction and
                      cover the gas fees
                    </li>
                    <li>
                      Upon confirmation, $CryptoFun will be deposited into your
                      wallet.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="phases-claim w-full md: px-[30px]">
                <div className="flex justify-center w-full pt-[50px] md:pt-[50px]">
                  <div className="max-w-[1226px] w-full mx-auto flex flex-col backgroundOrangeLinear rounded-[30px] p-[40px_24px] md:p-[70px_50px]">
                    <p className="text-center leading-normal blackTextClash mb-[15px] md:mb-[23px]">
                      Token Claim Phases
                    </p>
                    <p className="text-white text-center text-[18px] md:text-[28px]">
                      Investors from different presale phases have specific
                      waiting periods before they can claim their $CryptoFun
                      tokens post-Token Generation Event (TGE):
                    </p>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-[30px]  ease-in mt-20">
                  <div className="w-full md:w-1/3 flex flex-col">
                    <h1 className="blackTextClash mb-[10px] text-red">01.</h1>
                    <p className="text-black text-[28px] md:text-28 font-normal leading-10 tracking-[-0.56px]">
                      Phase 1 investors face a 60-day waiting period after TGE.
                    </p>
                  </div>
                  <div className="w-full md:w-1/3 flex flex-col">
                    <h1 className="blackTextClash mb-[10px] text-red">02.</h1>
                    <p className="text-black text-[28px] md:text-28 font-normal leading-10 tracking-[-0.56px]">
                      Phase 2 investors must wait 30 days post-TGE to start
                      their claim.
                    </p>
                  </div>
                  <div className="w-full md:w-1/3 flex flex-col">
                    <h1 className="blackTextClash mb-[10px] text-red">03.</h1>
                    <p className="text-black text-[28px] md:text-28 font-normal leading-10 tracking-[-0.56px]">
                      Phase 3 investors can claim their tokens immediately at
                      TGE.
                    </p>
                  </div>
                </div>
                <div className="text-center mt-20">
                  <p className="text-black text-[28px] md:text-28 font-normal leading-10 tracking-[-0.56px]">
                    When connecting their wallet, investors will see the total
                    $CryptoFun tokens they're eligible for and the amount
                    available to claim at that moment, acknowledging purchases
                    across multiple phases might stagger their claimable tokens.
                  </p>
                </div>
              </div>
            </section>
            <div className="w-full flex flex-col lg:flex-row gap-[50px] py-[40px]">
              <div className="w-full lg:w-1/2 flex flex-col gap-[30px]">
                <h1 className="orangeTextClash">
                  CryptoFun ($CryptoFun) Tokens
                </h1>
                <p className="text-[28px] leading-[42px] text-black font-[400]">
                  $CryptoFun is the native token of CryptoFun and the
                  surrounding CryptoFunverse.
                </p>
                <p className="text-[28px] leading-[42px] text-black font-[400]">
                  Alongside other special rewards, $CryptoFun can be earned
                  through completing missions within the game.
                </p>
                <p className="text-[28px] leading-[42px] text-black font-[400]">
                  Once acquired, $CryptoFun can be used to purchase special
                  in-game items via the CryptoFun Store - be it magic boosts,
                  health potions, weapons, and more. These upgrades will help
                  bolster your Play-to-Earn journey throughout Dreva.
                </p>
                <a
                  className="inline-block mt-[20px]"
                  id="bgRedCoWhite"
                  href="/buy"
                >
                  <svg
                    width="240"
                    height="60"
                    fill="transparent"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer transition duration-1000 ease-out text-[20px] font-medium capitalize"
                  >
                    <path
                      d="M229.619 35.5269C230.994 33.9371 231.75 31.9057 231.75 29.8042L231.75 9.99998C231.75 5.16749 227.832 1.24998 223 1.24998L9.99999 1.25C5.1675 1.25 1.25 5.16751 1.25 10L1.25 50C1.25 54.8325 5.1675 58.75 9.99999 58.75L205.539 58.75C208.08 58.75 210.496 57.6451 212.158 55.7227L229.619 35.5269Z"
                      stroke="black"
                      strokeWidth="2"
                    ></path>
                    <text x="50%" y="40" fill="black" text-anchor="middle">
                      Get Started
                    </text>
                  </svg>
                </a>
              </div>
              <div className="w-full lg:w-1/2">
                <img
                  alt="coins"
                  loading="lazy"
                  width="450"
                  height="390"
                  decoding="async"
                  data-nimg="1"
                  className=""
                  src="/images/Coins.svg"
                  style={{ color: "transparent" }}
                />
                <div>
                  <p className="textStake my-2">
                    The CryptoFun team is working tirelessly to democratise
                    $CryptoFun to the masses. This entails forming collaborative
                    partnerships and listing agreements with top-tier
                    centralised and decentralised exchanges.
                  </p>
                  <p className="textStake my-6">
                    However before listings begin, you still have the
                    opportunity to maximise your $CryptoFun portfolio via its
                    third and final Presale phase.
                  </p>
                  <p className="textStake my-6">
                    Here, you can become early members of GameFi’s most
                    promising Play-to-Earn community at a discounted rate…
                  </p>
                  <p className="textStake">
                    All whilst investing in a token that’s backed by a wealth of
                    GameFi utilities, as well as an ever-growing liquidity pool
                    as more and more investors join the ecosystem.
                  </p>
                </div>
              </div>
            </div>
            <h1 className="text-center blackTextClash font-semibold pt-6 md:pt-[50px] mb:pb-10">
              CryptoFunnomics
            </h1>
            <div className="w-full max-w-[1015px] mx-auto overflow-x-auto hideScrollbar mt-[20px] backgroundOrangeLinear rounded-[30px]">
              <div className="p-[36px] w-full min-w-[800px]">
                <div className="grid grid-cols-1">
                  <table className="min-w-full">
                    <thead className="">
                      <tr className="border-b-2 text-[16px] md:text-[18px] lg:text-[20px] font-[600] border-black text-left">
                        <th className="pb-[10px]">Token Holders</th>
                        <th className="pb-[10px]">Distribution</th>
                        <th className="pb-[10px]">Tokens</th>
                        <th className="pb-[10px]">Token Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="pb-[5px] pt-[15px]">
                          Pre Sale - Round 1
                        </td>
                        <td className="pb-[5px] pt-[15px]">10,00%</td>
                        <td className="pb-[5px] pt-[15px]">5.000.000.000</td>
                        <td className="pb-[5px] pt-[15px]">$0.0002</td>
                      </tr>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="py-[5px]">Pre Sale - Round 2</td>
                        <td className="py-[5px]">10,00%</td>
                        <td className="py-[5px]">5.000.000.000</td>
                        <td className="py-[5px]">$0.0004</td>
                      </tr>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="py-[5px]">Pre Sale - Round 3</td>
                        <td className="py-[5px]">10,00%</td>
                        <td className="py-[5px]">5.000.000.000</td>
                        <td className="py-[5px]">$0.0006</td>
                      </tr>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="py-[5px]">Liquidity Pool</td>
                        <td className="py-[5px]">7,50%</td>
                        <td className="py-[5px]">3.750.000.000</td>
                      </tr>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="py-[5px]">Team / Advisors</td>
                        <td className="py-[5px]">12,50%</td>
                        <td className="py-[5px]">6.250.000.000</td>
                      </tr>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="py-[5px]">Staking Rewards</td>
                        <td className="py-[5px]">10,00%</td>
                        <td className="py-[5px]">5.000.000.000</td>
                      </tr>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="py-[5px]">Ecosystem Development Fund</td>
                        <td className="py-[5px]">32,00%</td>
                        <td className="py-[5px]">16.000.000.000</td>
                      </tr>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="py-[5px]">Marketing</td>
                        <td className="py-[5px]">8,00%</td>
                        <td className="py-[5px]">4.000.000.000</td>
                      </tr>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="py-[5px]">Total</td>
                        <td className="py-[5px]">100%</td>
                        <td className="py-[5px]">50.000.000.000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center pt-[20px] md:pt-[80px]">
              <img
                alt="coins"
                loading="lazy"
                width="450"
                height="390"
                decoding="async"
                data-nimg="1"
                className="md:w-[100%] md:h-[350px] h-[250px] object-fill scale-[1.1] hidden md:block"
                src="/images/chart.svg"
                style={{ color: "transparent" }}
              />
              <img
                alt="coins"
                loading="lazy"
                width="450"
                height="390"
                decoding="async"
                data-nimg="1"
                className="w-[100%] block md:hidden"
                src="../images/mobile-chart.png"
                style={{ color: "transparent" }}
              />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-12 lg:gap-10 pb-12 pt-[50px]">
              <div className="col-span-12 md:col-span-6 lg:col-span-6 w-full lg:w-[95%] 2xl:w-[90%]">
                <p className="orangeTextClash">Transaction Tax</p>
                <p className="my-5 poppineText ">
                  CryptoFun imposes 0% tax on $CryptoFun purchases, however
                  there is a small 2.5% transaction tax on sell orders and
                  transfers.
                </p>
                <p className="my-5 textStake ">
                  $CryptoFun supports the overall development of all corners of
                  the CryptoFunverse, therefore meaning that these structures
                  serve as a tool for incentivising and rewarding those who hold
                  the token.
                </p>
              </div>
              <ul className="col-span-12 md:col-span-6 lg:col-span-6 w-full lg:w-[95%] 2xl:w-[85%] list-disc">
                <p className="orangeTextClash">Tax Revenue Distribution</p>
                <li className="my-2 poppineText">1% - Marketing Endeavours</li>
                <li className="my-2 poppineText">
                  1% - Ecosystem Development Fund/ Play-to-Earn Rewards
                </li>
                <li className="my-2 poppineText"> 0.5% - Burned (Forever!)</li>
              </ul>
            </div>
          </section>
        </div>

        <Blog />

        <Footer />
      </div>
    </>
  );
};

export default Token;
