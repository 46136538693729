const Blog = () => {
  return (
    <>
      <div className="bgSky pt-[50px]">
        <div className="background-containerFaqHome ">
          <div className="bgSky pt-[100px] pb-[50px] relative px-[30px]">
            <div className="absolute top-[60px] bottom-0 left-0 right-0 !pointer-events-none">
              <div className="flex justify-between items-center lg:sticky left-0 right-0 top-[0]">
                <img
                  alt="Cloud"
                  loading="lazy"
                  width="274"
                  height="152"
                  decoding="async"
                  data-nimg="1"
                  className="cloud-1 max-w-[40%]"
                  src="/images/cloud-1.429b1c46.svg"
                  style={{ color: "transparent" }}
                />
                <img
                  alt="Cloud"
                  loading="lazy"
                  width="319"
                  height="160"
                  decoding="async"
                  data-nimg="1"
                  className="cloud-2 max-w-[40%]"
                  src="/images/cloud-2.73cacac8.svg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="flex justify-between items-center lg:sticky left-0 right-0 top-[450px] mt-[450px]">
                <img
                  alt="Cloud"
                  loading="lazy"
                  width="327"
                  height="156"
                  decoding="async"
                  data-nimg="1"
                  className="cloud-3 max-w-[40%]"
                  src="/images/cloud-3.05115ebe.svg"
                  style={{ color: "transparent" }}
                />
                <img
                  alt="Cloud"
                  loading="lazy"
                  width="299"
                  height="253"
                  decoding="async"
                  data-nimg="1"
                  className="cloud-4 max-w-[40%]"
                  src="/images/cloud-4.5f81391a.svg"
                  style={{ color: "transparent" }}
                />
              </div>
            </div>
            <section className="max-w-[1226px] w-full mx-auto relative z-[2]">
              <h5 className="font-bold  text-[32px]  capitalize fontClash pt-10 pb-5 text-center text-black text-left  ">
                Frequently Asked Questions (FAQs)
              </h5>
              <div className="pt-[25px]">
                <div className="grid grid-cols-12 gap-7">
                  <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6 2xl:col-span-6">
                    <div>
                      <div className="mx-auto px-5 mt-2 borderAccordin ">
                        <div className="grid mx-auto">
                          <div className="py-5">
                            <details className="group">
                              <summary className="flex justify-between items-center cursor-pointer list-none">
                                <span className="textAccordin">
                                  What is CryptoFun and how does it differ from
                                  other play-to-earn games?
                                </span>
                                <span className="transition group-open:rotate-90">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="26"
                                    viewBox="0 0 15 26"
                                    fill="none"
                                  >
                                    <path
                                      d="M1.5 1.00021L13.0714 12.5716L1.5 24.1431"
                                      stroke="#9EB0CB"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                              </summary>
                              <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                Pikamoon differentiates itself in the
                                play-to-earn arena as a community-driven
                                project, challenging traditional gaming models.
                                Our approach is about fairness and empowerment
                                within web3, with a focus on player-centric
                                development. Funded and shaped by our community,
                                Pikamoon prioritizes the needs and interests of
                                players, ensuring a high-quality gaming
                                experience aligned with decentralization and
                                empowerment.By joining Pikamoon, players are
                                part of a revolutionary movement to make gaming
                                more inclusive and rewarding.
                              </p>
                            </details>
                          </div>
                        </div>
                      </div>
                      <div className="mx-auto px-5 mt-2 borderAccordin ">
                        <div className="grid mx-auto">
                          <div className="py-5">
                            <details className="group">
                              <summary className="flex justify-between items-center cursor-pointer list-none">
                                <span className="textAccordin">
                                  When will the presale conclude, and what are
                                  the next steps?
                                </span>
                                <span className="transition group-open:rotate-90">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="26"
                                    viewBox="0 0 15 26"
                                    fill="none"
                                  >
                                    <path
                                      d="M1.5 1.00021L13.0714 12.5716L1.5 24.1431"
                                      stroke="#9EB0CB"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                              </summary>
                              <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                The presale will conclude once the allocated 15
                                billion $CryptoFun tokens are sold out. We are
                                currently in the final phase, and once this
                                milestone is reached, Pikamoon will make its
                                debut on a Tier 1 centralized exchange. This
                                transition marks a significant step for
                                Pikamoon, expanding our reach and providing
                                liquidity for our investors. Participating in
                                the presale not only offers the benefit of early
                                investment but also signifies joining a
                                community-driven project set to redefine the
                                play-to-earn space.
                              </p>
                            </details>
                          </div>
                        </div>
                      </div>
                      <div className="mx-auto px-5 mt-2 borderAccordin ">
                        <div className="grid mx-auto">
                          <div className="py-5">
                            <details className="group">
                              <summary className="flex justify-between items-center cursor-pointer list-none">
                                <span className="textAccordin">
                                  How can I participate in the Pikamoon presale
                                  and what are the benefits of early investment?
                                </span>
                                <span className="transition group-open:rotate-90">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="26"
                                    viewBox="0 0 15 26"
                                    fill="none"
                                  >
                                    <path
                                      d="M1.5 1.00021L13.0714 12.5716L1.5 24.1431"
                                      stroke="#9EB0CB"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                              </summary>
                              <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                Participating in the Pikamoon presale is
                                straightforward. Interested investors can
                                purchase $CryptoFun tokens through our presale
                                dashboard on the pikamoon website. Early
                                investment in the presale phase not only allows
                                you to be part of Pikamoon's journey from its
                                inception but also offers benefits like
                                exclusive access to initial NFT drops and
                                priority entry to future events and tournaments.
                                As we approach the end of our final presale
                                phase, this is a unique opportunity to be among
                                the first to explore and shape the world of
                                Pikamoon.
                              </p>
                            </details>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 sm:col-span-12 md:col-span-12  lg:col-span-6 xl:col-span-6 2xl:col-span-6 mt-[-26px] md:mt-[0px]">
                    <div>
                      <div className="mx-auto px-5 mt-2 borderAccordin ">
                        <div className="grid mx-auto">
                          <div className="py-5">
                            <details className="group">
                              <summary className="flex justify-between items-center cursor-pointer list-none">
                                <span className="textAccordin">
                                  What are the functionalities and benefits of
                                  owning a Pikamoon NFT?
                                </span>
                                <span className="transition group-open:rotate-90">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="26"
                                    viewBox="0 0 15 26"
                                    fill="none"
                                  >
                                    <path
                                      d="M1.5 1.00021L13.0714 12.5716L1.5 24.1431"
                                      stroke="#9EB0CB"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                              </summary>
                              <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                Owning a Pikamoon NFT is your key to unlocking
                                the full potential of our metaverse. These NFTs
                                serve as more than just digital collectibles;
                                they are essential for participating in our
                                play-to-earn model. Holders can access exclusive
                                areas within Dreva, participate in eSports
                                tournaments, and have unique gaming experiences.
                                Additionally, NFT ownership may include future
                                benefits such as special in-game items, access
                                to exclusive events, and more as the Pikamoon
                                universe continues to evolve.
                              </p>
                            </details>
                          </div>
                        </div>
                      </div>
                      <div className="mx-auto px-5 mt-2 borderAccordin ">
                        <div className="grid mx-auto">
                          <div className="py-5">
                            <details className="group">
                              <summary className="flex justify-between items-center cursor-pointer list-none">
                                <span className="textAccordin">
                                  When is the Pikamoon metaverse expected to
                                  launch, and what can players anticipate?
                                </span>
                                <span className="transition group-open:rotate-90">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="26"
                                    viewBox="0 0 15 26"
                                    fill="none"
                                  >
                                    <path
                                      d="M1.5 1.00021L13.0714 12.5716L1.5 24.1431"
                                      stroke="#9EB0CB"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                              </summary>
                              <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                The Pikamoon Beta Battle System is currently
                                available for download on our website, giving
                                players a preview of our gaming innovation. Our
                                open metaverse is in development, with plans to
                                gradually unveil different world stages. In the
                                Pikamoon metaverse, players can look forward to
                                a rich, immersive experience with high-quality
                                graphics, diverse environments, and interactive
                                gameplay, offering a new realm of exploration
                                and adventure in Dreva. As we move closer to the
                                launch, we will be sharing more details and
                                sneak peeks of what's to come in the world of
                                Dreva.
                              </p>
                            </details>
                          </div>
                        </div>
                      </div>
                      <div className="mx-auto px-5 mt-2 borderAccordin ">
                        <div className="grid mx-auto">
                          <div className="py-5">
                            <details className="group">
                              <summary className="flex justify-between items-center cursor-pointer list-none">
                                <span className="textAccordin">
                                  How can I stay updated on Pikamoon’s
                                  latestnews and developments?
                                </span>
                                <span className="transition group-open:rotate-90">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="26"
                                    viewBox="0 0 15 26"
                                    fill="none"
                                  >
                                    <path
                                      d="M1.5 1.00021L13.0714 12.5716L1.5 24.1431"
                                      stroke="#9EB0CB"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                              </summary>
                              <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                Staying informed about Pikamoon's progress and
                                developments is easy. We encourage our community
                                to follow us on our official social media
                                channels, join our Discord or Telegram groups,
                                and regularly check our website for updates. We
                                frequently post news, updates, and announcements
                                across these platforms to keep our community
                                engaged and informed about every exciting step
                                in our journey.
                              </p>
                            </details>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href="https://t.me/pikamoonofficial"
                  target="_blank"
                  id="bgRedCoWhite"
                >
                  <svg
                    width="288"
                    height="60"
                    fill="transparent"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer transition duration-1000 ease-out text-[20px] text-center font-normal mt-14 mx-auto"
                  >
                    <path
                      d="M284.149 35.7614C285.968 34.0597 287 31.6804 287 29.1898L287 10C287 5.02943 282.971 0.999998 278 0.999999L10 1.00002C5.02943 1.00002 0.999995 5.02946 0.999996 10L0.999999 50C1 54.9706 5.02942 59 9.99998 59L255.761 59C258.045 59 260.243 58.1319 261.91 56.5716L284.149 35.7614Z"
                      stroke="black"
                      stroke-width="2"
                    ></path>
                    <text x="50%" y="40" fill="black" text-anchor="middle">
                      Join the Community
                    </text>
                  </svg>
                </a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
