import React, { useState } from "react";

const Footer = () => {
  return (
    <>
      <footer className="w-full relative bg-black pt-[80px] md:pt-[113px] px-[30px]">
        <div className="max-w-[1223px] flex flex-col gap-[20px] w-full mx-auto">
          <div className="w-full flex flex-col gap-[10px] mb-30px] md:mb-[60px]">
            <h3 className="co-orange text-[32px] leading-[39px] md:text-3xl font-bold fontClash text-center">
              Subscribe to the CryptoFun Newsletter
            </h3>
            <p className="max-w-[780px] w-full text-center mx-auto text-[#ffff] text-[20px] leading-[26px] font-[400]">
              With unmatched innovation, CryptoFun is set for GameFi &amp;
              Play-to-Earn stardom. Keep tabs on the movement by signing up to
              our newsletter below.
            </p>
            <div className="hidden md:flex flex-col items-center mt-[30px] md:mt-0">
              <button id="bgOrangeCoWhite" className="inline-block">
                <svg
                  width="620"
                  height="100"
                  fill="black"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cursor-pointer transition duration-1000 ease-out mx-auto py-3 text-2xl capitalize cursor-pointer mobDisplayHide"
                >
                  <path
                    d="M595.979 35.5789C597.747 33.9244 598.75 31.6112 598.75 29.1898L598.75 9.99997C598.75 5.16749 594.832 1.24997 590 1.24998L10 1.25003C5.1675 1.25003 1.25 5.16753 1.25 10L1.25 50C1.25 54.8325 5.16749 58.75 9.99997 58.75L567.761 58.75C569.981 58.75 572.118 57.906 573.739 56.389L595.979 35.5789Z"
                    stroke="white"
                    stroke-width="2"
                  ></path>
                  <text x="50%" y="40" fill="white" text-anchor="middle">
                    i want the latest news &amp; offers!
                  </text>
                </svg>
              </button>
             
            </div>
          </div>
          <div className="w-full">
            <div className="flex flex-wrap lg:flex-nowrap lg:gap-[50px] lg:gap-[100px]">
              <div className="w-full lg:w-1/3">
                <img
                  alt="close"
                  fetchpriority="high"
                  width="80"
                  height="80"
                  decoding="async"
                  data-nimg="1"
                  className="mb-4 pt-10 md:pt-0"
                  src="/images/logo.svg"
                  style={{ color: "transparent" }}
                />
                <p className="text-[20px] text-white lg:max-w-[385px] w-full font-[400]">
                  Centered around immersive 3D graphics, artistry, and gameplay,
                  CryptoFun is building one of the world's most advanced GameFi
                  metaverses.
                </p>
              </div>
              <div className="w-full sm:w-[65%] lg:w-1/3 flex gap-[50px] mt-[70px]">
                <div className="w-1/2 flex flex-col gap-[10px] text-[#A0A0A0] sm:text-[#9EB0CB] text-[18px]">
                  <a className="hover:text-white" href="/our-journey">
                    Our Journey
                  </a>
                  <a className="hover:text-white" href="/token">
                    Token
                  </a>
                  <a className="hover:text-white" href="/play-beta">
                    Play BETA
                  </a>
                  <a className="hover:text-white" href="/buy">
                    BUY $CryptoFun
                  </a>
                  <a className="hover:text-white" href="/faq">
                    FAQs
                  </a>
                  <a className="hover:text-white" href="/news">
                    $CryptoFun News
                  </a>
                </div>
                <div className="w-1/2 flex flex-col gap-[10px] text-[#A0A0A0] sm:text-[#9EB0CB] text-[18px]">
                  <a
                    target="_blank"
                    className="hover:text-white underline"
                    href="https://t.me/CryptoFunofficial"
                  >
                    Join Telegram
                  </a>
                  <a
                    target="_blank"
                    className="hover:text-white underline"
                    href="https://docs.CryptoFun.io/"
                  >
                    Whitepaper
                  </a>
                  <a
                    target=""
                    className="hover:text-white false"
                    href="/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                  <a
                    target=""
                    className="hover:text-white false"
                    href="/terms-of-use"
                  >
                    Terms Policy
                  </a>
                  <a
                    target=""
                    className="hover:text-white false"
                    href="/contact-us"
                  >
                    Contact Us
                  </a>
                  <a
                    target="_blank"
                    className="hover:text-white false"
                    href="https://hacken.io/audits/CryptoFun/"
                  >
                    Audit
                  </a>
                </div>
              </div>
              <div className="md:max-w-[200px] lg:max-w-[252px] w-[252px] sm:w-[35%] md:w-1/3 mt-[70px]">
                <p className="co-orange text-[20px]">Follow us!</p>
                <div className="flex md:flex-col mt-[20px]">
                  <div className="flex ">
                    <a target="_blank" href="https://twitter.com/CryptoFuncoin">
                      <img
                        alt="x"
                        fetchpriority="high"
                        width="50"
                        height="50"
                        decoding="async"
                        data-nimg="1"
                        className="hover:scale-[1.2] transtionHover cursor-pointer"
                        src="../images/X.svg"
                        style={{ color: "transparent" }}
                      />
                    </a>
                    <a target="_blank" href="https://t.me/CryptoFunofficial">
                      <img
                        alt="Telegram"
                        fetchpriority="high"
                        width="50"
                        height="50"
                        decoding="async"
                        data-nimg="1"
                        className="hover:scale-[1.2] transtionHover cursor-pointer"
                        src="../images/Telegram.svg"
                        style={{ color: "transparent" }}
                      />
                    </a>
                    <a
                      target="_blank"
                      href="https://discord.com/invite/QFHbbKQed9"
                    >
                      <img
                        alt="Discord"
                        fetchpriority="high"
                        width="50"
                        height="50"
                        decoding="async"
                        data-nimg="1"
                        className="hover:scale-[1.2] transtionHover cursor-pointer"
                        src="../images/Discord.svg"
                        style={{ color: "transparent" }}
                      />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/CryptoFuncoin_/"
                    >
                      <img
                        alt="Discord"
                        fetchpriority="high"
                        width="50"
                        height="50"
                        decoding="async"
                        data-nimg="1"
                        className="hover:scale-[1.2] transtionHover cursor-pointer"
                        src="../images/Instagram.svg"
                        style={{ color: "transparent" }}
                      />
                    </a>
                  </div>
                  <div className="flex  justify-center">
                    <a
                      target="_blank"
                      href="https://www.youtube.com/@CryptoFuncoin"
                    >
                      <img
                        alt="Discord"
                        fetchpriority="high"
                        width="50"
                        height="50"
                        decoding="async"
                        data-nimg="1"
                        className="hover:scale-[1.2] transtionHover cursor-pointer"
                        src="../images/youtubeicon.svg"
                        style={{ color: "transparent" }}
                      />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.reddit.com/r/officialCryptoFun/"
                    >
                      <img
                        alt="Discord"
                        fetchpriority="high"
                        width="50"
                        height="50"
                        decoding="async"
                        data-nimg="1"
                        className="hover:scale-[1.2] transtionHover cursor-pointer"
                        src="../images/reddit.svg"
                        style={{ color: "transparent" }}
                      />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.coingecko.com/en/coins/CryptoFun/"
                    >
                      <img
                        alt="Discord"
                        fetchpriority="high"
                        width="50"
                        height="50"
                        decoding="async"
                        data-nimg="1"
                        className="hover:scale-[1.2] transtionHover cursor-pointer w-[51px]"
                        src="../images/general-icon.svg"
                        style={{ color: "transparent" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-[20px] md:gap-0 md:items-center pt-[20px] md:pt-20 border-b border-[#D4D2E3] pb-12">
              <div className="w-full">
                <div className="max-w-[750px] mx-auto flex flex-col md:items-center">
                  <p className="co-orange font-bold mb-[5px] ">Disclaimer:</p>
                  <p className="co-white text-[14px] leading-[20px] md:text-center">
                    Cryptocurrency may be unregulated in your jurisdiction. The
                    value of cryptocurrencies may go down as well as up. Profits
                    may be subject to capital gains or other taxes applicable in
                    your jurisdiction.
                  </p>
                </div>
              </div>
            </div>
            <p className="text-sm text-white py-[26px] md:py-[40px] text-center">
              CryptoFun copyrights&nbsp;© 2024. All rights reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
